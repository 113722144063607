import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';
import { filterOutFalsyValues } from '@/lib';

import { COMPANY_QUERY_KEY } from '../types';

interface CompanyPayload {
  name: string;
  phone: string;
  state: string;
  country_code: string;
}

type Error = {
  message: string;
  response: {
    data: {
      detail?: string;
    };
  };
};

export const addCompany = ({ payload }: { payload: CompanyPayload }) => {
  const data = filterOutFalsyValues(payload);

  return restoreURL().post(`${API_CTX}/companies`, {
    ...data,
    status: 'ACTIVE',
  });
};

export const useAddCompany = ({ onSuccess }: { onSuccess?: () => void }) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(COMPANY_QUERY_KEY);
      toast.success('The company has been added successfully');

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      const errorDetail = error.response.data.detail;
      toast.error(
        `An error occurs: ${errorDetail ? errorDetail : error.message}`
      );
    },
    mutationFn: ({ payload }: { payload: CompanyPayload }) => {
      return addCompany({ payload });
    },
  });
};
