import { TOKEN_EXPIRY_LENGTH } from '../config';

import { diffFromNow, singleTruthNow } from './format';

const storagePrefix = 'taksoai_tool_';

const getStoredItem = (key: string) =>
  window.localStorage.getItem(key) as string;
const saveItem = (key: string, value: string) =>
  window.localStorage.setItem(key, value);
const deleteItem = (key: string) => window.localStorage.removeItem(key);

const storage = {
  clearToken: () => {
    deleteItem(`${storagePrefix}token`);
  },
  setToken: (token: string) => {
    saveItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  getToken: () => JSON.parse(getStoredItem(`${storagePrefix}token`)),
  getLoginTime: () => getStoredItem(`${storagePrefix}timestamp`),
  setLoginTime: () => {
    const timestamp = singleTruthNow();
    saveItem(`${storagePrefix}timestamp`, timestamp);
  },
  isTokenExpired: () => {
    const timestamp = getStoredItem(`${storagePrefix}timestamp`);
    if (!timestamp) return false;
    return diffFromNow(timestamp) > TOKEN_EXPIRY_LENGTH;
  },
};

export default storage;
