import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
};

type SelectFieldProps = FieldWrapperPassThroughProps & {
  name?: string;
  options: Option[];
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
  labelStyles?: string;
  wrapperStyles?: string;
  inputBorderStyles?: string;
};

export const SelectField = (props: SelectFieldProps) => {
  const {
    name,
    label,
    options,
    error,
    className,
    defaultValue,
    registration,
    placeholder,
    labelStyles,
    wrapperStyles,
    inputBorderStyles,
  } = props;
  return (
    <FieldWrapper
      label={label}
      error={error}
      className={labelStyles}
      wrapperStyles={wrapperStyles}
      inputBorderStyles={inputBorderStyles}
    >
      <select
        key={options.length.toString()}
        name={name}
        className={clsx(' py-2  border border-gray-500 ', className)}
        defaultValue={defaultValue}
        {...registration}
      >
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {options.map(({ label, value }) => (
          <option key={label?.toString()} value={value}>
            {label}
          </option>
        ))}
      </select>
    </FieldWrapper>
  );
};
