import { useContributors } from '../api/getContributors';

import { ContributorItem } from './ContributorItem';

interface ContributorProps {
  companyId: string;
}

export const ContributorList = ({ companyId }: ContributorProps) => {
  const contributorsQuery = useContributors({ companyId });
  const contributors = contributorsQuery.data || [];
  return (
    <>
      {contributors.map((contributor) => (
        <ContributorItem
          key={`${contributor.user.id}-${contributor.role.id}`}
          contributor={contributor}
        />
      ))}
    </>
  );
};
