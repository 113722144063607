import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import { Item } from '../../../types';

type ItemPropertiesModalProps = {
  show: boolean;
  handleClose: () => void;
  handleBack: () => void;
  properties?: Item['properties'];
  selectItemProperty: (itemProperty: Item['properties'][0]) => void;
  showItemPropertiesAddModal: () => void;
};

const ItemPropertiesModal = ({
  show,
  handleClose,
  handleBack,
  properties,
  selectItemProperty,
  showItemPropertiesAddModal,
}: ItemPropertiesModalProps) => {
  return (
    <Modal
      className="modal-constant-properties"
      as={Modal.Dialog}
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="h6">Item-properties</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-auto" style={{ height: '75vh' }}>
          {properties?.length ? (
            <table className="table table-hover equal-width-columns">
              <thead>
                <tr>
                  <th>Property name</th>
                  <th>Metric Lower Bound</th>
                  <th>Metric Upper Bound</th>
                  <th>Imperial Lower Bound</th>
                  <th>Imperial Upper Bound</th>
                </tr>
              </thead>
              <tbody>
                {properties?.map((prop) => (
                  <tr
                    onClick={() => selectItemProperty(prop)}
                    style={{ cursor: 'pointer' }}
                    key={prop.property_name}
                  >
                    <td>{prop.name}</td>
                    <td>{prop.metric_lower_bound || '-'}</td>
                    <td>{prop.metric_upper_bound || '-'}</td>
                    <td>{prop.imperial_lower_bound || '-'}</td>
                    <td>{prop.imperial_upper_bound || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>This item doesn't have any properties</p>
          )}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <Button onClick={handleBack} variant="light">
            Go back
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              showItemPropertiesAddModal();
            }}
            variant="secondary"
          >
            Create item-property
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ItemPropertiesModal;
