import { useQuery } from 'react-query';

import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { INVITATION_QUERY_KEY, Invitation } from '../types';
import { AxiosResponse } from 'axios';

const getInvitationForCompany = (
  id: string
): Promise<AxiosResponse<Invitation[] | undefined>> => {
  const companyFilter = `invite_company_id:eq:${id}`;
  const actionFilter = `action:eq:USER_INVITATION`;
  const filters = `filters=${companyFilter},${actionFilter}`;
  const url = `${API_CTX}/url-tokens?${filters}`;
  return restoreURL().get(url);
};

type QueryFnType = typeof getInvitationForCompany;

type UseInvitationsOptions = {
  companyId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useInvitations = ({
  companyId,
  config,
}: UseInvitationsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [INVITATION_QUERY_KEY, companyId],
    queryFn: () => getInvitationForCompany(companyId),
    enabled: !!companyId,
  });
};
