import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';
import { filterOutFalsyValues } from '@/lib';

import { COMPANY_QUERY_KEY } from '../types';

interface CompanyPayload {
  name: string;
  phone: string;
  state: string;
}

export const updateCompany = async ({
  companyId,
  payload,
}: {
  companyId: number;
  payload: CompanyPayload;
}) => {
  const data = filterOutFalsyValues(payload);

  await restoreURL().patch(`${API_CTX}/companies/${companyId}`, data);
};

export const useUpdateCompany = ({
  companyId,
  onSuccess,
}: {
  companyId: number;
  onSuccess?: () => void;
}) => {
  return useMutation<
    void,
    Error,
    { companyId: number; payload: CompanyPayload }
  >(({ payload }) => updateCompany({ companyId, payload }), {
    onSuccess: () => {
      queryClient.invalidateQueries(COMPANY_QUERY_KEY);
      toast.success(`The company was edited successfully`);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      toast.error(`An error occurs: ${error.message}`);
    },
  });
};
