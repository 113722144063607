import React, { useState } from 'react';

import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import { useAddCompany } from '../api';

type CompanyProfilesAddModalProps = {
  show: boolean;
  handleClose: () => void;
};

/**
 * Moved to folder `features/companies/modals` on:
 * @date 2024/09/04
 * @param param0
 * @returns
 */
export const CompanyProfilesAddModal = ({
  show,
  handleClose,
}: CompanyProfilesAddModalProps) => {
  const initialPayload = {
    name: '',
    phone: '',
    state: '',
    country_code: '',
  };

  const [payload, setPayload] = useState(initialPayload);

  const addCompany = useAddCompany({
    onSuccess: () => {
      handleClose();
      clearPayload();
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await addCompany.mutateAsync({ payload });
  };

  const clearPayload = () => {
    setPayload(initialPayload);
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={() => {
        handleClose();
        clearPayload();
      }}
    >
      <Modal.Header>
        <Modal.Title className="h6">Add Company</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={() => {
            handleClose();
            clearPayload();
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.name || ''}
                name="name"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.phone || ''}
                name="phone"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.state || ''}
                name="state"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Country Code</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.country_code || ''}
                name="country_code"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <div className="d-flex justify-content-start gap-3">
            <Button
              variant="light"
              onClick={() => {
                handleClose();
                clearPayload();
              }}
            >
              Cancel
            </Button>
            <Button variant="secondary" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
