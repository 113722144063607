import { useQuery } from 'react-query';

import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { User } from '../types';

export const getUsers = (): Promise<User[]> => {
  return restoreURL().get(`${API_CTX}/users`);
};

type QueryFnType = typeof getUsers;

type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
};

/**
 * Used in:
 * - `useAddContributorsByEmail/addContributors`
 * @param param0
 * @returns
 */
export const useUsers = ({ config }: UseUsersOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['users'],
    queryFn: () => getUsers(),
    staleTime: 60 * 1000, // 1 minute
  });
};
