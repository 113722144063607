import { CSSProperties } from 'react';

export const TOKEN_EXPIRY_LENGTH = 24 * 3600 * 1000; // in miliseconds

// ====== routes config section ======
export const TOKEN_UNAVAILABLE_FORWARD = '/auth/login';
export const LOGIN_SUCCESS_ROUTE = '/home';
export const PROFILE_ROUTE = '/home/profile';
export const USER_PROFILE_ROUTE = '/home/user-profile';
export const USER_DASHBOARD_ROUTE = '/home/dashboard';
export const ADMIN_DASHBOARD_URL = 'https://taksoai-dashboard.vercel.app';
// ====== end of routes config ======

export const isProductionEnv = process.env.NODE_ENV === 'production';

export const appVersion = `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`;

/**
 * Auth module Base URL, just a domain set from environment file(assigned in Vercel)
 */
export const API_URL = process.env.REACT_APP_API_URL as string;

/**
 * development env: /v1
 * production env in master branch: /prod
 *
 * @date 2024/08/19
 */
export const API_CTX = process.env.REACT_APP_API_CTX as string;

/**
 * TODO: Project module Base URL, use `Vercel` environment variables instead
 * @deprecated
 */
export const TK_AWS_API_URL =
  'https://0qaoeg3ip0.execute-api.us-east-1.amazonaws.com';
/**
 * @deprecated
 */
export const TK_API_PROJECTS = `/v1/projects`;
/**
 * @deprecated
 */
export const zoomifyBaseURL = `${TK_AWS_API_URL}/v1/`;

// export const TK_API_ITEMS = `${API_CTX}/items`;
// export const TK_API_PROPERTIES = `${API_CTX}/properties`;
// export const TK_USER_ME = `${API_CTX}/users/me`;

export const useScreenWidth =
  process.env.REACT_APP_ADAPT_SCREEN_WIDTH === 'true';

export const JWT_SECRET = '123456' as string;

export const PROJECTS_GALLERY_SLIDES = 15;
export const SLIDE_SHOW_COUNTS = 4;
export const TABS_OPEN_LIMIT = 5;
export const MAX_SINGLE_UPLOAD = 6;
export const INPUT_LENGTH_LIMIT = 24;
export const INPUT_LENGTH_SHORT = 12;

// *** UTILITIES FOR COMPONENTS ***

export const spinnerStyle: CSSProperties = {
  display: 'inline-block',
  margin: '0 auto',
};

export const settingsForSlick = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: SLIDE_SHOW_COUNTS,
  slidesToScroll: 2,
};
