import toast from 'react-hot-toast';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Form, Button, InputGroup } from 'react-bootstrap';

import AuthLayout from '../features/auth/components/AuthLayout';

import { requestPasswordReset } from '../features/auth/api/forgotPassword';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const [isSuccess, setIsSuccess] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const res = await requestPasswordReset(formData);

      if (res.status === 200) {
        setIsSuccess(true);
      }
    } catch (error: any) {
      toast.error(
        `An error occurs: ${JSON.parse(error.request.responseText).detail}`
      );
    }
  };

  return (
    <>
      <AuthLayout title="Forgot Password?" backButton>
        <div className="d-flex justify-content-center align-items-center mb-4">
          {isSuccess ? (
            <p>Email was sent to with the link to reset password</p>
          ) : (
            <p>
              Enter your E-mail and we'll send you a link to get back into your
              account
            </p>
          )}
        </div>
        <Form className="mt-5" onSubmit={handleSubmit}>
          {!isSuccess && (
            <Form.Group id="email" className="mb-4">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputGroup.Text>
                <Form.Control
                  name="email"
                  onChange={handleInputChange}
                  autoFocus
                  required
                  type="email"
                  placeholder="example@company.com"
                  value={formData.email}
                />
              </InputGroup>
            </Form.Group>
          )}
          <div className="d-flex flex-column justify-content-center align-items-center">
            {isSuccess && <p>Didn't receive email?</p>}
            <Button
              variant="primary"
              type="submit"
              className="mt-4 px-4 bg-blue"
            >
              {isSuccess ? 'Send Again' : 'Send'}
            </Button>
          </div>
        </Form>
      </AuthLayout>
    </>
  );
};

export default ForgotPassword;
