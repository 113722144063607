// export const selectCustomStyle = {
//   control: (baseStyles: any, state: any) => ({
//     ...baseStyles,
//     borderColor: state.isFocused ? 'grey' : '#B5C2DC',
//     borderRadius: 8,
//   }),
//   option: (baseStyles: any) => ({
//     ...baseStyles,
//     borderBottom: '1px solid #EEEEEE',
//   }),
// };

export const reactSelectStyle = {
  container: (provided: any) => ({
    ...provided,
    height: '100%',
    width: '200px',
  }),
  control: (provided: any) => ({
    ...provided,
    height: '100%',
    width: '200px',
    borderRadius: '8px',
  }),
};
