import { useState, useEffect } from 'react';

import { GO } from '@/types';

/**
 * Handling modal popup and close with a left callback
 *
 * @param onLeftViewport lazy left callback
 * @returns
 */
export function useModalAnimation(onLeftViewport?: () => void) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOnStage, setIsOnStage] = useState(false);

  // open modal by type
  const [activeModalType, setActiveModalType] = useState<string>('');
  const [modalContex, setModalContex] = useState<GO>({});

  const openModal = () => setIsOpen(true);

  /**
   * Open a specific modal
   *
   * @param type modal type
   * @param context data from dispatcher used in new modal
   */
  const activateModalBy = (type: string, context?: GO) => {
    setIsOpen(true);
    setActiveModalType(type);
    context && setModalContex(context);
  };

  const closeModal = () => {
    setIsOpen(false);
    setActiveModalType('');
    // if there is left action, do it
    onLeftViewport && onLeftViewport();
  };

  /**
   * This is commonly used to close a modal with fade out animation!
   */
  const lazyCloseHandler = (lastType?: string) => {
    // cache last used entity type to restore on next open!
    lastType && setModalContex({ type: lastType });
    setIsOnStage(false); // fade out
    setTimeout(closeModal, 200); // then hide
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setIsOnStage(true));
    } else {
      setIsOnStage(false);
    }
  }, [isOpen]);

  return {
    isOpen,
    isOnStage,
    lazyCloseHandler,
    openModal,
    activeModalType,
    modalContex,
    activateModalBy,
    comnModalProps: {
      isOpen,
      isOnStage,
      closeModal,
      lazyCloseHandler,
    },
  };
}
