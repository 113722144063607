import React from 'react';
import Navbar from '../components/Navbar';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { Col, Row } from 'react-bootstrap';
import data from '../mock-data/chart-data.json';

const JobStatusTracking = () => {
  return (
    <div className="overflow-hidden">
      <Row>
        <Col className="bg-dark sidebar d-flex flex-column">
          <Navbar activePage="jobs" />
        </Col>

        <Col className="mx-6">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: '100vh' }}
          >
            <p className="text-dark fs-4 mb-5">
              Job Counts by Execution Host and Status
            </p>
            <BarChart height={600} width={900} data={data}>
              <CartesianGrid
                stroke="#000000"
                strokeDasharray="5 5"
                vertical={false}
              />
              <XAxis dataKey="name" stroke="#000000" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="uv" fill="#0066b2" barSize={400} />
            </BarChart>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default JobStatusTracking;
