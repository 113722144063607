import { default as dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { snakeCase } from 'lodash';

dayjs.extend(relativeTime);

// ====== TIME HANDLING ======
export const formatDate = (date: number) =>
  dayjs(date).format('MMMM D, YYYY h:mm A');

export const formatDateByMDY = (timestamp: string) =>
  dayjs(timestamp).format('MMM DD, YYYY');

export const formatDateFromNow = (timestamp: string) =>
  dayjs(timestamp).fromNow();

/**
 * Get the difference in miliseconds
 *
 * @param timestamp time string
 * @returns
 */
export const diffFromNow = (timestamp: string) =>
  dayjs().diff(dayjs(timestamp));

export const singleTruthNow = () => dayjs().toISOString();

// ====== STRING HANDLING ======
export const shortenfyFileName = (
  fileName: string,
  maxFileLength = 22,
  suffix?: string
) => {
  // FIXME: fileName could be undefined
  // @2023/09/13
  if (!fileName) return 'unknown';

  return fileName.length > maxFileLength
    ? fileName.substring(0, maxFileLength) + `...${suffix || ''}`
    : fileName;
};

export const hashCode = (str: string) => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Unifed to snake case internally ...
 * for all the fitting `type` & `field` naming method
 */
export const kebabCase = snakeCase;

// export const kebabCase = (str: string) =>
//   str
//     .replace(/([a-z])([A-Z])/g, '$1-$2')
//     .replace(/[\s_]+/g, '-')
//     .toLowerCase();

export const removeSlashes = (str: string) => str.replace(/\//g, '');
export const removeBackSlashes = (str: string) => str.replace(/\\/g, '');

export const isDefined = (value: string | undefined) =>
  typeof value !== 'undefined';
