import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import { CATALOGUE_TYPE, FITTING_TYPE } from '../../../features/items/options';

import { useUpdateItem } from '../../../features/items/api';
import { useDeleteItem } from '../../../features/items/api/deleteItem';
import { useDeleteItemProperty } from '../../../features/item-properties/api';
import { Item } from '../../../types';

type ItemCatalogueEditModalProps = {
  show: boolean;
  handleClose: () => void;
  selectedItem: Item;
  showItemProperties: () => void;
};

const ItemCatalogueEditModal = ({
  show,
  handleClose,
  selectedItem,
  showItemProperties,
}: ItemCatalogueEditModalProps) => {
  const { id, category, type, description, properties } = selectedItem || {};

  const [payload, setPayload] = useState({
    category,
    type,
    description,
  });

  const [propertiesToDelete, setPropertiesToDelete] = useState<string[]>([]);

  useEffect(() => {
    setPayload({ category, type, description });
  }, [category, type, description]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: property, checked } = event.target;

    if (checked) {
      setPropertiesToDelete((state) => state.filter((p) => p === property));
    } else {
      setPropertiesToDelete((state) => [...state, property]);
    }
  };

  const updateItemMutation = useUpdateItem({
    onSuccess: () => {
      handleClose();
    },
    itemId: id,
  });

  const deleteItemProperty = useDeleteItemProperty({});

  const deleteItemMutation = useDeleteItem({ itemId: id });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await Promise.allSettled(
      propertiesToDelete.map((property) =>
        deleteItemProperty.mutateAsync({ id, name: property })
      )
    );

    await updateItemMutation.mutateAsync({ payload });
  };

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Edit Item</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Item ID</Form.Label>
            <InputGroup>
              <Form.Control disabled readOnly value={id} />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <InputGroup>
              <InputGroup>
                <Form.Control
                  value={payload.description || ''}
                  name="description"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <InputGroup>
              <Form.Select
                value={payload.type || ''}
                name="type"
                onChange={handleSelectChange}
              >
                {Object.values(CATALOGUE_TYPE).map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <InputGroup>
              <Form.Select
                value={payload.category || ''}
                name="category"
                onChange={handleSelectChange}
              >
                {Object.values(FITTING_TYPE).map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <div
            className="d-grid"
            style={{ gridTemplateColumns: '1fr 1fr', gap: '8px 64px' }}
          >
            {properties.map((property) => {
              const key = 'prop:' + property.name;

              return (
                <Form.Group
                  key={key}
                  className="d-flex justify-content-between"
                >
                  <label htmlFor={key}>{property.label}</label>
                  <Form.Check
                    name={property.name}
                    id={key}
                    onChange={handleCheckbox}
                    defaultChecked
                  />
                </Form.Group>
              );
            })}
          </div>
          <div className="d-flex justify-content-between mt-4">
            <div className="d-flex gap-3">
              <Button variant="light" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="secondary" type="submit">
                Save
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  deleteItemMutation.mutateAsync();
                }}
              >
                Delete
              </Button>
            </div>
            <Button onClick={showItemProperties} variant="secondary">
              Show properties of item
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ItemCatalogueEditModal;
