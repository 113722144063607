import { restoreURL } from '@/lib/axios';
import { API_CTX } from '@/config';
import { UserResponse } from '../types';

export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

export const loginWithEmailAndPassword = (
  data: LoginCredentialsDTO
): Promise<UserResponse> => {
  return restoreURL().post(`${API_CTX}/login`, data);
};
