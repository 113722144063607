import { ChangeEvent, useRef } from 'react';
import { z } from 'zod';

import { Form, InputField, SelectField } from '@/components/Form';
import { useUpdateUser } from '@/features/users';

import { ContributorDTO } from '../../users/types';
import { useChangeContributorRole } from '../api/changeContributorRole';
import { useRoles } from '../api/getRoles';
import { Contributor } from '../types';

import { ContributorMenu } from './ContributorMenu';

const schema = z.object({
  name: z.string().min(1, 'Required'),
  email: z.string().min(1, 'Required'),
  role_id: z.number(),
});

interface ContributorProps {
  contributor: Contributor;
}

export const ContributorItem = ({ contributor }: ContributorProps) => {
  const isDirtyRef = useRef(false);

  const changeContributorRoleMutation = useChangeContributorRole({});

  const onRoleChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    const roleId = +event.target.value;

    await changeContributorRoleMutation.mutateAsync({
      companyId: contributor.company.id,
      userId: contributor.user.id,
      prevRoleId: contributor.role.id,
      nextRoleId: roleId,
    });

    isDirtyRef.current = false;
  };

  const updateUserMutation = useUpdateUser({ userId: contributor.user.id });

  const handleUserUpdate = async (data: z.infer<typeof schema>) => {
    if (isDirtyRef.current) {
      await updateUserMutation.mutateAsync({ data });
      isDirtyRef.current = false;
    }
  };

  const { roleOptions } = useRoles({});

  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <Form<ContributorDTO['data'], typeof schema>
        id={`contributor-${contributor}`}
        onSubmit={() => console.log(`>> TODO: submit...`)}
        options={{
          defaultValues: {
            name: contributor.user.name,
            email: contributor.user.email,
            role_id: contributor.role.id,
          },
        }}
        schema={schema}
        className="d-flex flex-row w-75"
      >
        {({ register, formState, getValues }) => {
          const { isDirty } = formState;

          isDirtyRef.current = isDirty;

          return (
            <>
              <InputField
                label="Username"
                error={formState.errors['name']}
                registration={register('name', {
                  onBlur: () => handleUserUpdate(getValues()),
                })}
                placeholder="Username"
                labelStyles="fs-6"
                className="mx-1"
                wrapperStyles="w-auto"
              />

              <InputField
                label="Email"
                type="email"
                error={formState.errors['email']}
                registration={register('email', {
                  onBlur: () => handleUserUpdate(getValues()),
                })}
                placeholder="example@mail.com"
                labelStyles="fs-6 w-100"
                className="w-100"
                wrapperStyles="w-50"
              />
              <div className="w-auto">
                {roleOptions && (
                  <SelectField
                    name="role_id"
                    label="Access level"
                    className="px-2"
                    labelStyles="fs-6 "
                    wrapperStyles="mx-1"
                    placeholder="Select Role"
                    error={formState.errors['role_id']}
                    registration={register('role_id', {
                      onChange: onRoleChange,
                      value: contributor.role.id,
                    })}
                    options={roleOptions}
                  />
                )}
              </div>
            </>
          );
        }}
      </Form>
      <ContributorMenu contributor={contributor} />
    </div>
  );
};
