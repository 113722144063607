import { XMarkIcon } from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { UseFormProps, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

import { ModalOpenProps } from '@/components/Modals/modal';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { InputField, SelectField } from '@/components/Form';

import { Company } from '@/types';

import { ContributorField } from '../types';
import { useAddContributorsByEmail } from '../api/addContributors';

import { useRoles } from '../api/getRoles';

const contributorsSchema = z.object({
  contributors: z
    .array(
      z.object({
        email: z.string().email().min(1, 'Required'),
        role_id: z.string().min(1, 'Required'),
      })
    )
    .min(1, 'Required'),
});

const contributorsInitial: ContributorField[] = [{ email: '', role_id: '1' }];

function useZodForm<TSchema extends z.ZodTypeAny>(
  props: Omit<UseFormProps<TSchema['_input']>, 'resolver'> & {
    schema: TSchema;
  }
) {
  const form = useForm<TSchema['_input']>({
    ...props,
    resolver: zodResolver(props.schema, undefined, {}),
  });

  return form;
}

export const AddContributorsModal = ({
  isOpen,
  closeModal,
  companyProfile,
}: ModalOpenProps & { companyProfile: Company }) => {
  const {
    handleSubmit,
    register,
    control,
    // isDirty: false because there getValues() === defaultValues
    formState: { errors, isDirty },
  } = useZodForm({
    schema: contributorsSchema,
    defaultValues: { contributors: contributorsInitial },
    mode: 'onChange',
  });

  const { fields, remove } = useFieldArray({
    name: 'contributors',
    control,
  });

  const addContributorsMutation = useAddContributorsByEmail({
    companyId: companyProfile.id,
  });

  const addContributors = async (contributorFields: ContributorField[]) => {
    try {
      await addContributorsMutation.mutateAsync({
        company_id: companyProfile.id,
        contributorFields,
      });
    } catch (error: any) {
      console.error(error?.message);
      toast.error('Add Contributor Failed!');
    }
  };

  const { roleOptions } = useRoles({});

  // const handleAddContributorField = () => {
  //   append({
  //     email: '',
  //     role_id: '',
  //   });
  // };
  // console.log(roleOptions);

  const handleRemoveContributor = (index: number) => {
    remove(index);
  };

  const isButtonDisabled = !isDirty || Object.keys(errors).length > 0;

  return (
    <Modal show={isOpen} onHide={() => closeModal({})} title="Add Contributors">
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">
          Add Signed up User | {companyProfile.name}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          id="add-contributors"
          onSubmit={handleSubmit(async (values) => {
            console.log(values);
            await addContributors(values.contributors);
            closeModal({});
          })}
          className="space-y-6"
        >
          <>
            {/* Render email and role_id fields for each contributor */}
            <>
              {fields.map((contributorField, index) => {
                const errorsForField = errors?.contributors?.[index];

                return (
                  <div className="row gx-4" key={contributorField.id}>
                    <InputField
                      label="Email *"
                      type="email"
                      error={errorsForField?.email}
                      registration={register(`contributors.${index}.email`)}
                      placeholder="example@mail.com"
                      labelStyles="text-xs leading-none"
                      wrapperStyles="col"
                      className="w-72 fs-6 px-2"
                    />
                    {roleOptions && (
                      <SelectField
                        label="Access level *"
                        placeholder="Select Role"
                        error={errorsForField?.role_id}
                        registration={register(`contributors.${index}.role_id`)}
                        labelStyles="text-xs leading-none col"
                        options={roleOptions}
                        wrapperStyles="col"
                        inputBorderStyles="border-end border-light-subtle"
                        className="fs-6 px-2"
                      />
                    )}
                    {fields.length > 1 && (
                      <div className="mt-5">
                        <button
                          type="button"
                          onClick={() => handleRemoveContributor(index)}
                          className="btn-icon-only p-1 focus:outline-none w-7 h-7 justify-self-center"
                        >
                          <XMarkIcon
                            className="w-5 h-5 heroicon-sw-3"
                            color="#555085"
                          />
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </>

            {/* Render form controls */}
            <div className=" d-flex justify-content-end mt-4 px-4">
              <Button
                type="reset"
                variant="outline-dark"
                className="flex-1"
                size="lg"
                onClick={() => closeModal({})}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="success"
                className="w-full flex-1 leading-5 text-base ms-4"
                size="lg"
                disabled={isButtonDisabled}
              >
                Apply
              </Button>
            </div>
          </>
        </form>
      </Modal.Body>
    </Modal>
  );
};
