import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import { Property } from '../../../types';

type ConstantPropertiesModalProps = {
  show: boolean;
  handleClose: () => void;
  onPropClick: (prop: Property['constant_values'][0]) => void;
  handleBack: () => void;
  selectedItem?: Property;
  showConstantPropertiesAddModal: () => void;
};

const ConstantPropertiesModal = ({
  show,
  handleClose,
  onPropClick,
  handleBack,
  selectedItem,
  showConstantPropertiesAddModal,
}: ConstantPropertiesModalProps) => {
  const { constant_values } = selectedItem || {};

  return (
    <Modal
      className="modal-constant-properties"
      as={Modal.Dialog}
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="h6">Constant-properties</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-auto" style={{ height: '75vh' }}>
          {constant_values?.length ? (
            <table className="table table-hover equal-width-columns">
              <thead>
                <tr>
                  <th>Acronym</th>
                  <th>Category</th>
                  <th>Color Code</th>
                  <th>Constant Value</th>
                  <th>Description</th>
                  <th>Position</th>
                  <th>Property Name</th>
                </tr>
              </thead>
              <tbody>
                {constant_values?.map((item) => (
                  <tr
                    onClick={(e) => {
                      e.preventDefault();
                      onPropClick(item);
                    }}
                    style={{ cursor: 'pointer' }}
                    key={item.constant_value}
                  >
                    <td>Acronym: {item.acronym}</td>
                    <td>Category: {item.category}</td>
                    <td>Color Code: {item.color_code}</td>
                    <td>Constant Value: {item.constant_value}</td>
                    <td>Description: {item.description}</td>
                    <td>Position: {item.position}</td>
                    <td>Property Name: {item.property_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>This property doesn't have constant-properties</p>
          )}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <Button onClick={handleBack} variant="light">
            Go back
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              showConstantPropertiesAddModal();
            }}
            variant="secondary"
          >
            Create constant-property
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConstantPropertiesModal;
