import React, { useState } from 'react';
import {
  PropertiesModal,
  ConstantPropertiesModal,
  ConstantPropertiesAddModal,
  ConstantPropertiesEditModal,
} from '../Modals';
import { Property } from '../../types';

type SystemPropertiesTableProps = {
  properties?: Property[];
};

const SystemPropertiesTable = ({
  properties: allProperties,
}: SystemPropertiesTableProps) => {
  const [activeModal, setActiveModal] = useState('');
  const [selectedItem, setSelectedItem] = useState<Property | undefined>();
  const [selectedConstantProp, setSelectedConstantProp] = useState<
    Property['constant_values'][0]
  >({
    acronym: '',
    category: '',
    color_code: '',
    constant_value: '',
    description: '',
    position: 0,
    property_name: '',
  });

  const handleClose = () => {
    setSelectedItem(undefined);
    setActiveModal('');
  };

  if (!allProperties) return <div>Loading...</div>;

  return (
    <>
      <ConstantPropertiesEditModal
        show={activeModal === 'constant-properties-edit'}
        handleClose={handleClose}
        selectedConstantProperty={selectedConstantProp}
        handleBack={() => setActiveModal('constant-properties')}
      />
      <ConstantPropertiesAddModal
        show={activeModal === 'constant-add-properties'}
        handleClose={() => {
          handleClose();
        }}
        selectedItem={selectedItem}
        handleBack={() => setActiveModal('constant-properties')}
      />
      <ConstantPropertiesModal
        show={activeModal === 'constant-properties'}
        handleClose={handleClose}
        onPropClick={(prop: Property['constant_values'][0]) => {
          setSelectedConstantProp(prop);
          setActiveModal('constant-properties-edit');
        }}
        handleBack={() => setActiveModal('properties')}
        selectedItem={selectedItem}
        showConstantPropertiesAddModal={() =>
          setActiveModal('constant-add-properties')
        }
      />
      <PropertiesModal
        show={activeModal === 'properties'}
        handleClose={handleClose}
        selectedItem={selectedItem}
        showConstantPropertiesModal={() =>
          setActiveModal('constant-properties')
        }
      />
      <table className="table table-hover equal-width-columns">
        <thead>
          <tr>
            <th>Name</th>
            <th>Label</th>
            <th>Date Type</th>
            <th>Date Created</th>
            <th>Created By</th>
            <th>Last Modified By</th>
            <th>Date Modified</th>
          </tr>
        </thead>
        <tbody>
          {allProperties.map((prop, idx) => (
            <tr
              style={{ cursor: 'pointer' }}
              key={idx}
              onClick={() => {
                setSelectedItem(prop);
                setActiveModal('properties');
              }}
            >
              <td>{prop.name || '-'}</td>
              <td>{prop.label || '-'}</td>
              <td>{prop.data_type || '-'}</td>
              <td>{prop.date_created || '-'}</td>
              <td>{prop.created_by || '-'}</td>
              <td>{prop.last_modified_by || '-'}</td>
              <td>{prop.date_modified || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SystemPropertiesTable;
