import { Modal, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { ModalOpenProps } from '@/components/Modals/modal';

import { useActivateCompany } from '../api';
import { Company } from '../types';

/**
 * @date created at 2024/10/07
 */
export const CompanyRestoreModal = ({
  isOpen,
  closeModal,
  companyProfile,
}: ModalOpenProps & { companyProfile: Company }) => {
  const activeCompanyMutation = useActivateCompany({});

  const handleSubmit = async () => {
    const companyId = companyProfile.id;
    await activeCompanyMutation.mutateAsync(companyId);
    closeModal({});
  };

  return (
    <Modal show={isOpen} onHide={() => closeModal({})} title="Restore Company">
      <Modal.Header>
        <Modal.Title className="h6">
          {`Activate Company | ${companyProfile.name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="p-2">Are you sure you want to activate this company?</p>
          <div className="d-flex justify-content-end gap-3">
            <Button variant="secondary" onClick={handleSubmit}>
              {!activeCompanyMutation.isLoading ? (
                'Yes'
              ) : (
                <Spinner size="sm" animation="border" />
              )}
            </Button>
            <Button variant="light" onClick={() => closeModal({})}>
              No
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
