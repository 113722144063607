import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import { useAddConstantProperty } from '../../../features/constant-props/api';

import { FITTING_TYPE } from '../../../features/items/options';
import { Property } from '../../../types';

type ConstantPropertiesAddModalProps = {
  show: boolean;
  handleClose: () => void;
  handleBack: () => void;
  selectedItem?: Property;
};

const ConstantPropertiesAddModal = ({
  show,
  handleClose,
  handleBack,
  selectedItem,
}: ConstantPropertiesAddModalProps) => {
  const initialPayload = {
    acronym: '',
    category: FITTING_TYPE.D,
    color_code: '',
    constant_value: '',
    description: '',
    position: 0,
  };

  const [payload, setPayload] = useState(initialPayload);

  const addConstantProperty = useAddConstantProperty({
    onSuccess: () => {
      handleClose();
      clearPayload();
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!selectedItem) return;
    const name = selectedItem.name;

    event.preventDefault();
    await addConstantProperty.mutateAsync({ name, payload });
  };

  const clearPayload = () => {
    setPayload(initialPayload);
  };

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Add Constant-properties</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Acronym</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.acronym || ''}
                name="acronym"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <InputGroup>
              <Form.Select
                value={payload.category || ''}
                name="category"
                onChange={handleSelectChange}
              >
                {Object.values(FITTING_TYPE).map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Color Code</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.color_code || ''}
                name="color_code"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Constant Value</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.constant_value || ''}
                name="constant_value"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.description || ''}
                name="description"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Position</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.position || ''}
                name="position"
                type="number"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <div className="d-flex justify-content-between mt-3">
            <Button onClick={handleBack} variant="light">
              Go back
            </Button>
            <Button type="submit" variant="secondary">
              Create
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ConstantPropertiesAddModal;
