import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import { useAddItemProperty } from '../../../features/item-properties/api';
import { Property } from '../../../types';

const initialPayload = {
  property_name: '',
  metric_lower_bound: 0,
  metric_upper_bound: 0,
  imperial_lower_bound: 0,
  imperial_upper_bound: 0,
};

type ItemPropertiesAddModalProps = {
  show: boolean;
  handleClose: () => void;
  handleBack: () => void;
  itemId?: string;
  properties?: Property[];
};

const ItemPropertiesAddModal = ({
  show,
  handleClose,
  handleBack,
  itemId,
  properties,
}: ItemPropertiesAddModalProps) => {
  const [payload, setPayload] = useState(initialPayload);

  const clearPayload = () => {
    setPayload(initialPayload);
  };

  const addItemProperty = useAddItemProperty({
    onSuccess: () => {
      handleClose();
      clearPayload();
    },
  });

  const setInputValue = (name: string, value: string) => {
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInputValue(name, value);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;

    setInputValue(name, value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!itemId) return;

    event.preventDefault();
    await addItemProperty.mutateAsync({ id: itemId, payload });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={() => {
        handleClose();
        clearPayload();
      }}
    >
      <Modal.Header>
        <Modal.Title className="h6">
          Associate a property with an item
        </Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={() => {
            handleClose();
            clearPayload();
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Property name</Form.Label>
            <InputGroup>
              <Form.Select
                value={payload.property_name || ''}
                name="property_name"
                onChange={handleSelectChange}
              >
                {properties?.map((property) => (
                  <option value={property.name} key={property.name}>
                    {property.name}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Metric lower bound</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.metric_lower_bound || ''}
                name="metric_lower_bound"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Metric upper bound</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.metric_upper_bound || ''}
                name="metric_upper_bound"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Imperial lower bound</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.imperial_lower_bound || ''}
                name="imperial_lower_bound"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Imperial upper bound</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.imperial_upper_bound || ''}
                name="imperial_upper_bound"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <div className="d-flex justify-content-between mt-3">
            <Button
              onClick={() => {
                handleBack();
                clearPayload();
              }}
              variant="light"
            >
              Go back
            </Button>
            <Button type="submit" variant="secondary">
              Create
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ItemPropertiesAddModal;
