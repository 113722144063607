import toast from 'react-hot-toast';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faUnlockAlt,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { Form, Card, Button, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import AuthLayout from '../features/auth/components/AuthLayout';

import { Routes } from '../routes';
import { useAuth } from '../lib/auth';

import Spinner from 'react-bootstrap/Spinner';

type FormInputs = {
  id?: string;
  password: string;
  email: string;
};

const Login = () => {
  const [formData, setFormData] = useState<FormInputs>({
    password: '',
    email: '',
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const history = useHistory();
  const { user, login, isLoggingIn } = useAuth();

  useEffect(() => {
    const homePage = Routes.HomeScreen.path;
    const loginPage = Routes.SignIn.path;
    const target = user ? homePage : loginPage;
    history.push(target);
    // TODO:
    // !user && dropNotification();
  }, [user, history]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const res = await login(formData);
      if (res && res.id) {
        history.push(Routes.HomeScreen.path);
      }
    } catch (error: any) {
      toast.error(
        `An error occurs: ${JSON.parse(error.request.responseText).detail}`
      );
    }
  };

  return (
    <>
      <AuthLayout backButton={false} title="Log In">
        <Form className="mt-5" onSubmit={handleSubmit}>
          <Form.Group id="email" className="mb-4">
            <Form.Label>Email Address</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroup.Text>
              <Form.Control
                name="email"
                onChange={handleInputChange}
                autoFocus
                required
                type="email"
                placeholder="example@company.com"
                value={formData.email}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group id="password" className="mb-4">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUnlockAlt} />
              </InputGroup.Text>
              <Form.Control
                type={passwordVisible ? 'text' : 'password'}
                name="password"
                onChange={handleInputChange}
                required
                placeholder="Password"
                value={formData.password}
              />
              <InputGroup.Text
                onClick={togglePasswordVisibility}
                style={{
                  borderRight: '0.0625rem solid #d1d7e0',
                  cursor: 'pointer',
                }}
              >
                {passwordVisible ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <div className="d-flex justify-content-end align-items-center mb-4">
            <Card.Link
              as={Link}
              className="link-primary small text-end"
              to={Routes.ForgotPassword.path}
            >
              Forgot Password?
            </Card.Link>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              type="submit"
              className="mt-4 px-4 bg-blue w-24"
            >
              {isLoggingIn ? (
                <Spinner size="sm" animation="border" />
              ) : (
                `Log in`
              )}
            </Button>
          </div>
        </Form>
      </AuthLayout>
    </>
  );
};

export default Login;
