import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWarehouse,
  faDesktop,
  faDatabase,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';
import { HashLink } from 'react-router-hash-link';
import { Nav } from 'react-bootstrap';
import { Routes } from '../routes';

const Navbar = ({ activePage }: { activePage?: string }) => {
  return (
    <div className="position-sticky">
      <ul className="nav flex-column py-2">
        <li className={`nav-item ${activePage === 'home' ? 'active' : ''}`}>
          <Nav.Link
            className="d-flex justify-content-center py-3"
            as={HashLink}
            to={Routes.HomeScreen.path}
            title="Home page"
          >
            <FontAwesomeIcon size="lg" icon={faWarehouse} />
          </Nav.Link>
        </li>
        <li className={`nav-item ${activePage === 'jobs' ? 'active' : ''}`}>
          <Nav.Link
            className="d-flex justify-content-center py-3"
            as={HashLink}
            to={Routes.JobStatusTracking.path}
            title="Jobs"
          >
            <FontAwesomeIcon size="lg" icon={faDesktop} />
          </Nav.Link>
        </li>
        <li
          className={`nav-item ${activePage === 'catalogue' ? 'active' : ''}`}
        >
          <Nav.Link
            className="d-flex justify-content-center py-3"
            as={HashLink}
            to={Routes.ItemCatalogue.path}
            title="Items"
          >
            <FontAwesomeIcon size="lg" icon={faDatabase} />
          </Nav.Link>
        </li>
        <li className={`nav-item ${activePage === 'company' ? 'active' : ''}`}>
          <Nav.Link
            className="d-flex justify-content-center py-3"
            as={HashLink}
            to={Routes.CompanyProfiles.path}
            title="Company Admin"
          >
            <FontAwesomeIcon size="lg" icon={faBuilding} />
          </Nav.Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
