/**
 * Caught backend returned errors
 */
export const AUTHERRORCODES = [400, 401, 403, 440];

/** these two cases need to renew the token */
export const TOKENINVALIDCODES = [401, 440];

export const HTTPErrorEvent = {
  /** Missing token in request */
  BADREQUEST: 'badRequest',
  /** Invalid or expired token, Invalid credentials (user or password) */
  UNAUTHORIZED: 'unauthorized',
  /** Valid token but user’ session is expired */
  EXPIRED: 'expired',
  /** illegal to access resources */
  FORBIDDEN: 'forbidden',
};

export const HTTP_CODE: {
  [key: number]: {
    type: string;
    description: string;
    action: string;
    event: string;
  };
} = {
  400: {
    type: 'bad request',
    description: 'missing token in request',
    action: 'add token in headers of request',
    event: HTTPErrorEvent.BADREQUEST,
  },
  401: {
    type: 'unauthorized',
    description:
      'Invalid or expired token, Invalid credentials (user or password)',
    action: 'login to obtain a fresh token',
    event: HTTPErrorEvent.UNAUTHORIZED,
  },
  403: {
    type: 'forbidden',
    description: 'Valid token but restricted permission',
    action: 'validate user’s role(s)',
    event: HTTPErrorEvent.FORBIDDEN,
  },
  440: {
    type: 'session expired or time out',
    description: 'Valid token but user’ session is expired',
    action: 'login',
    event: HTTPErrorEvent.EXPIRED,
  },
};
