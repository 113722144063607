import { Modal, Button } from 'react-bootstrap';

import { Property } from '@/types';
import { useDeleteCompany } from '../api';

type CompanyProfilesDeleteModalProps = {
  id: number;
  show: boolean;
  handleCloseMainModal: () => void;
  handleCloseDeleteModal: () => void;
  selectedItem?: Property;
};

const CompanyProfilesDeleteModal = ({
  id,
  show,
  handleCloseMainModal,
  handleCloseDeleteModal,
}: CompanyProfilesDeleteModalProps) => {
  const deleteCompanyMutation = useDeleteCompany({
    onSuccess: () => {
      handleCloseMainModal();
      handleCloseDeleteModal();
    },
  });

  const handleSubmit = async () => {
    await deleteCompanyMutation.mutateAsync(id);
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={handleCloseDeleteModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">Delete Company</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={handleCloseDeleteModal}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-start gap-3">
          <span>Are you sure you want to delete this company?</span>
          <Button variant="secondary" onClick={handleSubmit}>
            Yes
          </Button>
          <Button variant="light" onClick={handleCloseDeleteModal}>
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyProfilesDeleteModal;
