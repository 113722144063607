import React from 'react';
import Navbar from '../components/Navbar';
import { Col, Row } from 'react-bootstrap';

const HomeScreen = () => {
  return (
    <div className="overflow-hidden">
      <Row>
        <Col className="bg-dark sidebar d-flex flex-column">
          <Navbar activePage="home" />
        </Col>

        <div className="right-col">
          <div className="d-flex justify-content-center align-items-center p-4">
            <h2 className="text-center ">TaksoAI Status Overview</h2>
          </div>
          {/* === ROW 1 === */}
          <Row className="p-4">
            <Col>
              <div className="shadow-sm card border-light">
                <div className="card-body">
                  <h4>Tool state overview</h4>
                  <p>Tool running statistics placeholder</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="shadow-sm card border-light">
                <div className="card-body">
                  <h4>Jobs chart by day</h4>
                  <p>Tool running statistics placeholder</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="shadow-sm card border-light">
                <div className="card-body">
                  <h4>System load statistics</h4>
                  <p>Tool running statistics placeholder</p>
                </div>
              </div>
            </Col>
          </Row>
          {/* === ROW 2 === */}
          <Row className="p-4">
            <Col>
              <div className="shadow-sm card border-light">
                <div className="card-body">
                  <h4>Frontend issues statistics</h4>
                  <p>Tool running statistics placeholder</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="shadow-sm card border-light">
                <div className="card-body">
                  <h4>Backend issues by day</h4>
                  <p>Tool running statistics placeholder</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="shadow-sm card border-light">
                <div className="card-body">
                  <h4>AI server exceptions</h4>
                  <p>Tool running statistics placeholder</p>
                </div>
              </div>
            </Col>
          </Row>
          {/* === ROW 3 === */}
          <Row className="p-4">
            <Col>
              <div className="shadow-sm card border-light">
                <div className="card-body">
                  <h4>New User statistics</h4>
                  <p>Tool running statistics placeholder</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="shadow-sm card border-light">
                <div className="card-body">
                  <h4>Active users statistics</h4>
                  <p>Tool running statistics placeholder</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="shadow-sm card border-light">
                <div className="card-body">
                  <h4>Infrastructure usage statistics</h4>
                  <p>Tool running statistics placeholder</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default HomeScreen;
