import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';
import { filterOutFalsyValues } from '@/lib';

interface ItemPayload {
  id: string;
  description: string;
  type: string;
  category: string;
}

/** payload
  type: CATALOGUE_TYPE; // LENGTH, FITTING ...
  category: string; // PIPE | DUCT
  description: string;
  id: string;
  s3_icon_path: string;
  properties: TKItemProperty[];
 */

export const addItem = async ({ payload }: { payload: ItemPayload }) => {
  const data = filterOutFalsyValues(payload);

  await restoreURL().post(`${API_CTX}/items`, data);
};

export const useAddItem = ({ onSuccess }: { onSuccess?: () => void }) => {
  return useMutation<void, Error, { payload: ItemPayload }>(
    ({ payload }) => addItem({ payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['items']);
        toast.success(`The item has been added successfully`);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (error: Error) => {
        toast.error(`An error occurs: ${error.message}`);
      },
    }
  );
};
