import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';
import { filterOutFalsyValues } from '@/lib';

interface ItemPayload {
  name: string;
  label: string;
  data_type: string;
}

export const addProperty = async ({ payload }: { payload: ItemPayload }) => {
  const data = filterOutFalsyValues(payload);

  await restoreURL().post(`${API_CTX}/properties`, data);
};

export const useAddProperty = ({ onSuccess }: { onSuccess?: () => void }) => {
  return useMutation<void, Error, { payload: ItemPayload }>(
    ({ payload }) => addProperty({ payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['properties']);
        toast.success(`The property has been added successfully`);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (error: Error) => {
        toast.error(`An error occurs: ${error.message}`);
      },
    }
  );
};
