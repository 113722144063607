export const DimensionSuffix = {
  MM: 'mm',
  I: 'in',
  M: 'm',
  FT: 'ft',
};

export const PAGE_UNIT = {
  M: 'metric',
  I: 'imperial',
};

export const SPECIAL_JOB_ITEM = {
  RECT_DUCT: 'RECTANGULAR_DUCT',
  OFFSET: 'OFFSET_ELBOW',
  EQMT: 'EQUIPMENT',
  FLOW: 'DIRECTION_OF_FLOW',
  LABEL: 'LABEL',
};

export const SYMBOL_MODE = {
  INI: 'INITIAL',
  NEW: 'NEW',
  MOD: 'MODIFIED',
  DEL: 'DELETED',
  MOV: 'TRANSLATED',
  MIX: 'MOD_AND_MOV',
  SMS: 'SEG_MOD_SIZ',
  SMP: 'SEG_MOD_PRO',
  SMX: 'SEG_MOD_MIX',
  SDE: 'SEG_DELETE',
};

export const SYMBOL_REVISION = {
  A: '0',
  B: '1',
  C: '2',
  D: '3',
};

export const DETECTION_MODE = {
  A: 'AI',
  M: 'MANUAL',
};

export const CATALOGUE_TYPE = {
  F: 'FITTING',
  L: 'LENGTH',
  LB: 'LABEL',
  UN: 'UNASSIGNED',
  OF: 'OFFSET',
};

export const PROPERTY_TYPE = {
  S: "STRING",
  N: "NUMBER"
};

export const FITTING_TYPE = {
  D: 'DUCT',
  P: 'PIPE',
  E: 'EQUIPMENT',
};

export const FLUID_TYPE = {
  W: 'WATER',
  G: 'GASES',
  V: 'VENT',
};

export const LENGTH_TYPE = {
  P: 'PIPE_LENGTH',
  D: 'DUCT_LENGTH',
};

export const FILTERS = {
  DEFAULT: 'default',
  ALL: 'all',
  DUCTS: 'duct_fittings',
  PIPES: 'pipe_fittings',
  DLENT: 'straight_duct',
  PLENT: 'straight_pipe',
  LENT: 'straight_line',
  MXPIPE: 'pipe_fitting_length',
  MXDUCT: 'duct_fitting_length',
  EQMT: 'equipment',
  OFST: 'offset',
};

export const SymbolType = {
  RECT: 'rect',
  POLYGON: 'polygon',
  EQUIPMENT: 'point',
  DOT: 'circle',
  LABEL: 'label',
  OFFSET: 'offset',
  SEGMENT: 'singleline',
};

export const FEATURESTATE = {
  N: 'normal',
  A: 'active',
  H: 'hovered',
  D: 'disabled',
  L: 'highlighted',
  T: 'textHidden',
};

export const FEATUREMODES = {
  A: 'auto',
  M: 'modify',
  T: 'translate',
};

export const FIXTUREATTRS = {
  DIAMETER: 'diameter_1',
  HEIGHT: 'height_1',
  WIDTH: 'width_1',
  D2: 'diameter_2',
  H2: 'height_2',
  W2: 'width_2',
};

export const DUCTSHAPES = {
  ROUND: 'round',
  SQUARE: 'rectangular',
};

export const DUCTSHAPEOPTS = [
  {
    label: 'Round',
    value: DUCTSHAPES.ROUND,
  },
  {
    label: 'Rectangular',
    value: DUCTSHAPES.SQUARE,
  },
];

export const PageTypes = [
  'HVAC',
  'Piping',
  'Equipment',
  'HVAC $ Piping',
  'HVAC & Piping & Equipment',
];

export const AIModules = {
  E: 'equipment_type',
  H: 'hvac_type',
  P: 'piping_type',
  T: 'table_type',
};

export const AIModuleOptions = [
  {
    label: 'Equipment',
    value: AIModules.E,
  },
  {
    label: 'HVAC',
    value: AIModules.H,
  },
  {
    label: 'Piping',
    value: AIModules.P,
  },
];

export const CONSTRUCTSECTOR = {
  A: 'A',
  B: 'B',
};

export const PROJECTSTATE = {
  T: 'TENDERING',
  R: 'REVIEW',
  S: 'SUBMITTED',
  F: 'FINISHED',
  I: 'INACTIVE',
};

export const FILECONTYPE = {
  PDF: 'pdf',
  DEFAULT: 'Other',
  HVAC: 'HVAC',
  PIPE: 'Piping',
  HPMIX: 'HVAC and Piping',
  OTHER: 'Other',
  ELECTRIC: 'electric',
};

export const FILESTATUS = {
  LOCKED: 'LOCKED',
  PENDING: 'PENDING',
  EXTRACTING_PAGES: 'EXTRACTING_PAGES',
  GENERATING_THUMBNAILS: 'GENERATING_THUMBNAILS',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
};

export const JOBSTATUS = {
  INIT: 'ISNULL',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
};

export const PAGESTATUS = {
  INIT: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  ZOOMIFY: 'GENERATING_ZOOMIFY',
  PROCESSING: 'PROCESSING_AI',
  COMPLETE: 'COMPLETED',
  ERROR: 'ERROR',
  LOCKED: 'LOCKED',
  INVALID: 'INVALID',
  PREALGO: 'PREALGO',
  REVIEWFITTINGS: 'REVIEWFITTINGS',
  REVIEWSYSTEMS: 'REVIEWSYSTEMS',
  SAVING: 'SAVING',
  REVIEWED: 'REVIEWD',
  DONE: 'DONE',
  INACTIVE: 'INACTIVE',
};

export const DRAWINGSYMBOL = {
  CARBON_DIOXIDE: 'CARBON DIOXIDE',
  CENTER_LINE_REDUCER: 'center line reducer',
  ECCENTRIC_REDUCER: 'eccentric reducer',
  ELBOW: 'elbow',
  FIRE_DAMPER: 'Fire Damper',
  PERIMETER_DIFFUSER: 'perimeter diffuser',
  POLYGON_RECTANGULAR_DUCT: 'Polygon Rectangular Duct',
  RECTANGULAR_DUCT: 'Rectangular Duct',
  RETURN_DUCT: 'Return Duct',
  ROUND_DUCT_DOWN: 'Round duct Down',
  SUPPLY_AIR_DIFFUSER: 'Supply Air Diffuser',
  SUPPLY_OR_OUTSIDE_AIR_DUCT: 'Supply or Outside Air Duct',
  THEROSTAT: 'Therostat',
  Y_JUNCTION: 'Y junction',
};
