/**
 * Created at 2024/08/01
 */
import { FC } from 'react';

import { MODAL_TYPES as MDT } from '@/types/options';
import { ModalComnProps } from '@/components/Modals/modal';

import { AddContributorsModal } from './AddContributorsModal';
import { CompanyProfilesEditModal } from './CompanyProfilesEditModal';
import { InviteUserModal } from './InviteUserModal';
import { UserDeleteConfirmation } from './UserDeleteConfirmation';
import { CompanyRestoreModal } from './CompanyRestoreModal';

import { Company } from '../types';

// TODO: include it to `TKCompanyModals`
export * from './CompanyProfilesAddModal';

type UserModalExtraProps = {
  companyId: string;
  companyProfile: Company;
  userId: string;
  userName: string;
};

export type TKGeneModalProps = {
  commons: ModalComnProps;
  extra: UserModalExtraProps;
};

const TKCompanyModals: {
  [key: string]: FC<TKGeneModalProps>;
} = {
  [MDT.ADD_CONTRIBUTORS]: ({ commons, extra }) => (
    <AddContributorsModal {...commons} {...extra} />
  ),
  [MDT.EDIT_GROUP]: ({ commons, extra }) => (
    <CompanyProfilesEditModal {...commons} {...extra} />
  ),
  [MDT.INVITE_NEW_USER]: ({ commons, extra }) => (
    <InviteUserModal {...commons} {...extra} />
  ),
  [MDT.DELETE_USER]: ({ commons, extra }) => (
    <UserDeleteConfirmation {...commons} {...extra} />
  ),
  [MDT.RESTORE_GROUP]: ({ commons, extra }) => (
    <CompanyRestoreModal {...commons} {...extra} />
  ),
  dummy: () => <div className="hidden">x</div>,
};

export const TKCompanyModal = ({
  type,
  props,
}: {
  type: string;
  props: TKGeneModalProps;
}) => {
  const CompanyModal = TKCompanyModals[type] || TKCompanyModals.dummy;
  return <CompanyModal {...props} />;
};
