import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';

import { COMPANY_QUERY_KEY } from '../types';

export const activateCompany = async (companyId: string) => {
  await restoreURL().patch(`${API_CTX}/companies/${companyId}`, {
    status: 'ACTIVE',
  });
};

/**
 * @date created at 2024/10/07
 */
export const useActivateCompany = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(COMPANY_QUERY_KEY);
      toast.success(`The company was being activated successfully`);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      toast.error(`An error occurs: ${error.message}`);
    },
    mutationFn: (companyId: string) => {
      return activateCompany(companyId);
    },
  });
};
