import Accordion from 'react-bootstrap/Accordion';

import { GO } from '@/types';

import { ContributorList } from './ContributorList';
import { InvitationList } from './InvitationList';
import { Company } from '../types';
import { CompanyOperationTools } from './CompanyOperationTools';

export const CompaniesList = ({
  companies,
  openBy,
}: {
  companies: Company[];
  openBy: (type: string, context?: GO) => void;
}) => {
  const style4 = (company: Company) => {
    const deadStyle = 'text-through text-black-50';
    return company.status === 'DELETED' ? deadStyle : '';
  };
  return (
    <Accordion>
      {companies.map((company) => (
        <Accordion.Item eventKey={company.id} key={company.id}>
          <Accordion.Header>
            <span className={style4(company)}>
              #{company.id}-{company.name}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            {/* === Add | Send | Edit */}
            <CompanyOperationTools openBy={openBy} company={company} />
            {/* === user list inside of this company! */}
            <ContributorList companyId={company.id} />
            {/* === Invitation list === */}
            <InvitationList companyId={company.id} />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
