import toast from 'react-hot-toast';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUnlockAlt,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import AuthLayout from '../features/auth/components/AuthLayout';

import { Routes } from '../routes';
import { useResetPasswordAPI } from '../features/auth/hooks';

type PasswordVisible = {
  password?: string;
  confirmPassword?: string;
};

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [passwordVisible, setPasswordVisible] = useState<PasswordVisible>({});

  const history = useHistory();

  const { onFormSubmit } = useResetPasswordAPI();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = (name: 'password' | 'confirmPassword') => {
    setPasswordVisible((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const res = await onFormSubmit(formData);

      if (res && res.status === 200) {
        history.push(Routes.HomeScreen.path);
      }
    } catch (error) {
      toast.error('Error');
    }
  };

  return (
    <>
      <AuthLayout title="Reset Password" backButton>
        <Form className="mt-5" onSubmit={handleSubmit}>
          <Form.Group id="password" className="mb-4">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUnlockAlt} />
              </InputGroup.Text>
              <Form.Control
                name="password"
                onChange={handleInputChange}
                autoFocus
                required
                type={passwordVisible.password ? 'text' : 'password'}
                value={formData.password}
              />
              <InputGroup.Text
                onClick={() => togglePasswordVisibility('password')}
                style={{
                  borderRight: '0.0625rem solid #d1d7e0',
                  cursor: 'pointer',
                }}
              >
                {passwordVisible.password ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="confirmPassword" className="mb-4">
            <Form.Label>Confirm Password</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUnlockAlt} />
              </InputGroup.Text>
              <Form.Control
                name="confirmPassword"
                onChange={handleInputChange}
                required
                type={passwordVisible.confirmPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
              />
              <InputGroup.Text
                onClick={() => togglePasswordVisibility('confirmPassword')}
                style={{
                  borderRight: '0.0625rem solid #d1d7e0',
                  cursor: 'pointer',
                }}
              >
                {passwordVisible.confirmPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Button
              variant="primary"
              type="submit"
              className="mt-4 px-4 bg-blue"
            >
              Reset Password
            </Button>
          </div>
        </Form>
      </AuthLayout>
    </>
  );
};

export default ResetPassword;
