import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';

export const deleteItem = ({ itemId }: { itemId: string }) => {
  return restoreURL().delete(`${API_CTX}/items/${itemId}`);
};

export const useDeleteItem = ({
  itemId,
  onSuccess,
}: {
  itemId: string;
  onSuccess?: () => void;
}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['items']);
      toast.success('The item was deleted successfully');

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      toast.error(`An error occurs: ${error.message}`);
    },
    mutationFn: () => {
      return deleteItem({ itemId });
    },
  });
};
