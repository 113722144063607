import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import { ModalOpenProps } from '@/components/Modals/modal';
import CompanyProfilesDeleteModal from './CompanyProfilesDeleteModal';

import { Company } from '@/types';

import { useUpdateCompany } from '../api';

/**
 * Company Edit Modal based on simple `CompanyProfilesDeleteModal`
 * @param param0
 * @returns
 */
export const CompanyProfilesEditModal = ({
  isOpen,
  closeModal,
  companyProfile,
}: ModalOpenProps & { companyProfile: Company }) => {
  const { id, name, phone, state } = companyProfile;
  const [payload, setPayload] = useState({
    name,
    phone,
    state,
  });

  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

  useEffect(() => {
    setPayload({ name, phone, state });
  }, [name, phone, state]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };
  // show delete confirmation modal!
  const toggleDeleteModal = () => setIsDeleteModalShown(true);

  const updateCompanyMutation = useUpdateCompany({
    onSuccess: () => {
      closeModal({});
    },
    companyId: parseInt(id as string),
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!id) return;

    await updateCompanyMutation.mutateAsync({
      companyId: parseInt(id),
      payload,
    });
  };

  return (
    <>
      {/* Delete confirmation modal to do real deletion */}
      <CompanyProfilesDeleteModal
        id={parseInt(id)}
        show={isDeleteModalShown}
        handleCloseMainModal={() => closeModal({})}
        handleCloseDeleteModal={() => setIsDeleteModalShown(false)}
      />
      {/* Normal modal for company edit mode */}
      {!isDeleteModalShown && (
        <Modal
          as={Modal.Dialog}
          centered
          show={isOpen}
          onHide={() => closeModal({})}
        >
          <Modal.Header>
            <Modal.Title className="h6">Edit Item</Modal.Title>
            <Button
              variant="close"
              aria-label="Close"
              onClick={() => closeModal({})}
            />
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>ID</Form.Label>
                <InputGroup>
                  <Form.Control disabled readOnly value={id} />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    value={payload.name || ''}
                    name="name"
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <InputGroup>
                  <Form.Control
                    value={payload.phone || ''}
                    name="phone"
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <InputGroup>
                  <Form.Control
                    value={payload.state || ''}
                    name="state"
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <div className="d-flex justify-content-start gap-3">
                <Button variant="light" onClick={() => closeModal({})}>
                  Cancel
                </Button>
                <Button variant="secondary" type="submit">
                  Save
                </Button>
                <Button variant="danger" onClick={toggleDeleteModal}>
                  Delete
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

// export default CompanyProfilesEditModal;
