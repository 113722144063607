import { useState } from 'react';
import Select from 'react-select';
import { Container, Col, Row } from 'react-bootstrap';

import { reactSelectStyle } from '@/config/model';
import { useModalAnimation } from '@/hooks/useModalAnimation';

import Navbar from '../components/Navbar';

import {
  CompaniesList,
  TKCompanyModal,
  CompanyProfilesAddModal,
  Company,
  useCompanies,
} from '@/features/companies';

import { UserManageTable } from '@/features/users';

const options = [
  { value: 'ALL', label: 'All' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'DELETED', label: 'Deleted' },
];

type SelectValue = { value: string; label: string };

const CompanyProfiles = () => {
  const [selectedOption, setSelectedOption] = useState<SelectValue>(options[1]);
  const companiesQuery = useCompanies();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    comnModalProps: commons,
    modalContex,
    activeModalType,
    activateModalBy: openBy,
  } = useModalAnimation();

  const companyStateFilter = (companyProfile: Company) => {
    // check selected company state:
    switch (selectedOption.value) {
      case 'ALL':
        return true;
      case 'ACTIVE':
        return companyProfile.status === 'ACTIVE';
      case 'DELETED':
        return companyProfile.status === 'DELETED';
      default:
        return false;
    }
  };
  const filteredCompanies: Company[] | undefined =
    companiesQuery.data?.filter(companyStateFilter);

  return (
    <>
      <Container>
        <Row>
          <Col className="bg-dark sidebar d-flex flex-column">
            <Navbar activePage="company" />
          </Col>

          <Col className="ms-6 me-4 ">
            <div className="d-flex flex-column align-items-center mt-4">
              {/* Page Title */}
              <h3 className="text-center">Company Management</h3>
              <div
                className="d-flex justify-content-end w-100"
                style={{ gap: '30px' }}
              >
                {/* === Company Display filter by status === */}
                <Select
                  defaultValue={selectedOption}
                  onChange={(value) => setSelectedOption(value as SelectValue)}
                  options={options}
                  styles={reactSelectStyle}
                />
                {/* === Create New Company === */}
                <button
                  className="btn btn-primary"
                  onClick={() => setIsModalOpen(true)}
                >
                  Add New Company
                </button>
              </div>
              {/* === Company list */}
              <div className="mt-4 p-0 container">
                {filteredCompanies ? (
                  <CompaniesList
                    openBy={openBy}
                    companies={filteredCompanies}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {/* ===== USER TABLE ===== */}
        <Row>
          <Col className="my-5 ms-6 me-4">
            <h3 className="text-center">User Management</h3>
            <UserManageTable openBy={openBy} />
          </Col>
        </Row>
      </Container>
      {/* === Add contributor === */}
      <TKCompanyModal
        type={activeModalType}
        props={{
          commons,
          extra: {
            companyId: modalContex.companyId,
            companyProfile: modalContex.companyProfile,
            userId: modalContex.userId,
            userName: modalContex.userName,
          },
        }}
      />
      {/* === Add company TODO: move this to `TKCompanyModal` === */}
      <CompanyProfilesAddModal
        show={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default CompanyProfiles;
