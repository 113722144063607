import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';
import { filterOutFalsyValues } from '@/lib';

interface Payload {
  description: string;
  type: string;
  category: string;
}

/** payload
  type: CATALOGUE_TYPE; // LENGTH, FITTING ...
  category: string; // PIPE | DUCT
  description: string;
  id: string;
  s3_icon_path: string;
  properties: TKItemProperty[];
 */

export const updateItem = ({
  itemId,
  payload,
}: {
  itemId: string;
  payload: Payload;
}) => {
  const data = filterOutFalsyValues(payload);

  return restoreURL().patch(`${API_CTX}/items/${itemId}`, data);
};

export const useUpdateItem = ({
  itemId,
  onSuccess,
}: {
  itemId: string;
  onSuccess?: () => void;
}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['items']);
      toast.success('The item was edited successfully');

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      toast.error(`An error occurs: ${error.message}`);
    },
    mutationFn: ({ payload }: { payload: Payload }) => {
      return updateItem({ itemId, payload });
    },
  });
};
