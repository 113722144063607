export const filterOutFalsyValues = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce(
    (filtered, key) => {
      if (obj[key]) {
        filtered[key] = obj[key];
      }

      return filtered;
    },
    {} as Record<string, any>
  );
};
