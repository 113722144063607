import { useQuery } from 'react-query';

import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';
import { Property } from '@/types';

export const getProperties = async () => {
  return restoreURL()
    .get(`${API_CTX}/properties`)
    .then((response) => {
      return response.data.documents;
    });
};

export const useProperties = () => {
  return useQuery<Property[], Error>({
    queryKey: ['properties'],
    queryFn: getProperties,
  });
};
