import { useState } from 'react';

import { ModalOpenProps } from '@/components/Modals/modal';
import { Modal, Button, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { useDeleteUser } from '@/features/users';

type SimpleUser = { userId: string; userName: string };

export const UserDeleteConfirmation = ({
  isOpen,
  closeModal,
  userId,
  userName,
}: ModalOpenProps & SimpleUser) => {
  const [sending, setSending] = useState(false);
  const userMutaion = useDeleteUser({});

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSending(true);
    await userMutaion.mutateAsync(userId);
    setSending(false);
    closeModal({});
  };

  return (
    <Modal show={isOpen} onHide={() => closeModal({})} title="Delete User">
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">Delete User Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex justify-center">
        <Form id="invite-user" onSubmit={handleSubmit} className="w-75">
          <Form.Group className="mb-3">
            <Form.Label>Are you sure to delete user:</Form.Label>
            <Form.Control
              readOnly
              type="text"
              name="user-name"
              value={userName}
            />
          </Form.Group>
          <Form.Group className="mb-3" hidden>
            <Form.Label>User Id</Form.Label>
            <Form.Control type="text" name="user-id" value={userId} />
          </Form.Group>
          <div className="d-flex justify-content-end gap-3">
            <Button variant="light" onClick={() => closeModal({})}>
              Cancel
            </Button>
            {/* Send Invitation */}
            <Button variant="success" type="submit" disabled={sending}>
              {sending && (
                <>
                  <Spinner size="sm" animation="border" />
                  ......
                </>
              )}
              {!sending && `Delete`}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
