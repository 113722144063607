import React, { useState } from 'react';
import {
  ItemCatalogueEditModal,
  ItemPropertiesAddModal,
  ItemPropertiesEditModal,
  ItemPropertiesModal,
} from '../Modals';
import { Item, Property } from '../../types';

type ItemCatalogueTableProps = {
  data: (Item | undefined)[] | undefined;
  properties?: Property[];
};

const ItemCatalogueTable = ({
  data: items,
  properties: allProperties,
}: ItemCatalogueTableProps) => {
  const [activeModal, setActiveModal] = useState('');
  const [selectedItem, setSelectedItem] = useState<Item | undefined>();
  const [selectedItemProperty, setSelectedItemProperty] =
    useState<Item['properties'][0]>();

  const handleClose = () => {
    setSelectedItem(undefined);
    setActiveModal('');
  };

  if (!items || !allProperties) return <div>Loading...</div>;

  return (
    <>
      {
        <ItemPropertiesEditModal
          show={activeModal === 'item-properties-edit'}
          handleClose={handleClose}
          handleBack={() => setActiveModal('item-properties')}
          itemId={selectedItem?.id}
          selectedItemProperty={selectedItemProperty}
        />
      }
      {
        <ItemPropertiesAddModal
          show={activeModal === 'item-properties-add'}
          handleClose={handleClose}
          handleBack={() => setActiveModal('item-properties')}
          itemId={selectedItem?.id}
          properties={allProperties.filter(
            (p) =>
              !selectedItem?.properties
                .map((prop) => prop.name)
                .includes(p.name)
          )}
        />
      }
      {
        <ItemPropertiesModal
          show={activeModal === 'item-properties'}
          handleClose={handleClose}
          handleBack={() => setActiveModal('item-edit')}
          properties={selectedItem?.properties}
          selectItemProperty={(itemProperty: Item['properties'][0]) => {
            setSelectedItemProperty(itemProperty);
            setActiveModal('item-properties-edit');
          }}
          showItemPropertiesAddModal={() =>
            setActiveModal('item-properties-add')
          }
        />
      }
      {selectedItem && (
        <ItemCatalogueEditModal
          show={activeModal === 'item-edit'}
          handleClose={handleClose}
          selectedItem={selectedItem}
          showItemProperties={() => setActiveModal('item-properties')}
        />
      )}
      <table className="table table-hover equal-width-columns">
        <thead>
          <tr>
            <th>Item ID</th>
            <th>Description</th>
            <th>Type</th>
            <th>Category</th>
            {allProperties?.map((property) => (
              <th key={property?.name}>{property?.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedItem(item);
                setActiveModal('item-edit');
              }}
              key={item?.id}
            >
              <td>{item?.id}</td>
              <td>{item?.description}</td>
              <td>{item?.type}</td>
              <td>{item?.category}</td>
              {allProperties?.map((property) => {
                const matchingProperty = item?.properties.find(
                  (itemProperty) => itemProperty.name === property?.name
                );

                const formattedValue = matchingProperty
                  ? `${matchingProperty.metric_lower_bound}-${matchingProperty.metric_upper_bound}`
                  : '_';

                return <td key={property?.name}>{formattedValue}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ItemCatalogueTable;
