import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import { useAddItem } from '../../../features/items/api';

import { CATALOGUE_TYPE, FITTING_TYPE } from '../../../features/items/options';

type ItemCatalogueAddModalProps = {
  show: boolean;
  handleClose: () => void;
};

const ItemCatalogueAddModal = ({
  show,
  handleClose,
}: ItemCatalogueAddModalProps) => {
  const initialPayload = {
    id: '',
    description: '',
    type: CATALOGUE_TYPE.F,
    category: FITTING_TYPE.D,
  };

  const [payload, setPayload] = useState(initialPayload);

  const addItem = useAddItem({
    onSuccess: () => {
      handleClose();
      clearPayload();
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await addItem.mutateAsync({ payload });
  };

  const clearPayload = () => {
    setPayload(initialPayload); // Reset payload to its initial state
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={() => {
        handleClose();
        clearPayload();
      }}
    >
      <Modal.Header>
        <Modal.Title className="h6">Add Item</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={() => {
            handleClose();
            clearPayload();
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Item ID</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.id || ''}
                name="id"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <InputGroup>
              <InputGroup>
                <Form.Control
                  value={payload.description || ''}
                  name="description"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <InputGroup>
              <Form.Select
                value={payload.type || CATALOGUE_TYPE.F}
                name="type"
                onChange={handleSelectChange}
              >
                {Object.values(CATALOGUE_TYPE).map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <InputGroup>
              <Form.Select
                value={payload.category || FITTING_TYPE.D}
                name="category"
                onChange={handleSelectChange}
              >
                {Object.values(FITTING_TYPE).map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <div className="d-flex mb-3" style={{ gap: '3rem' }}>
            <Form.Group
              className="d-flex justify-content-between w-50"
              style={{ height: 'fit-content' }}
            >
              <label>Height</label>
              <Form.Check type="switch" onChange={handleSwitchChange} />
            </Form.Group>
            <Form.Group
              className="d-flex justify-content-between w-50"
              style={{ height: 'fit-content' }}
            >
              <label>Diffuser_code</label>
              <Form.Check type="switch" onChange={handleSwitchChange} />
            </Form.Group>
          </div>
          <Form.Group
            className="d-flex justify-content-between w-50 mb-3"
            style={{ height: 'fit-content', paddingRight: '1.45rem' }}
          >
            <label>Diameter</label>
            <Form.Check type="switch" onChange={handleSwitchChange} />
          </Form.Group>
          <Form.Group
            className="d-flex justify-content-between w-50 mb-3"
            style={{ height: 'fit-content', paddingRight: '1.45rem' }}
          >
            <label>Is_vertical</label>
            <Form.Check type="switch" onChange={handleSwitchChange} />
          </Form.Group>
          <div className="d-flex justify-content-start gap-3">
            <Button
              variant="light"
              onClick={() => {
                handleClose();
                clearPayload();
              }}
            >
              Cancel
            </Button>
            <Button variant="secondary" type="submit">
              Add Item
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ItemCatalogueAddModal;
