import React from 'react';

interface TableSwitcherProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const TableSwitcher = ({ activeTab, setActiveTab }: TableSwitcherProps) => {
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item me-2">
          <button
            className={`nav-link ${activeTab === 'table1' ? 'active' : ''}`}
            onClick={() => handleTabChange('table1')}
          >
            Base information
          </button>
        </li>
        <li className="nav-item me-2">
          <button
            className={`nav-link ${activeTab === 'table2' ? 'active' : ''}`}
            onClick={() => handleTabChange('table2')}
          >
            System Properties
          </button>
        </li>
      </ul>
      <div className="tab-content">
        <div
          className={`tab-pane fade ${
            activeTab === 'table1' ? 'show active' : ''
          }`}
          id="table1"
        >
          <table className="table"></table>
        </div>
        <div
          className={`tab-pane fade ${
            activeTab === 'table2' ? 'show active' : ''
          }`}
          id="table2"
        >
          <table className="table"></table>
        </div>
      </div>
    </div>
  );
};

export default TableSwitcher;
