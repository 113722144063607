import { orderBy } from 'lodash';
import Table from 'react-bootstrap/Table';

import { GO } from '@/types';
import { MODAL_TYPES as MDT } from '@/types/options';
import { useUsers } from '@/features/users';

/**
 * Created at 2024/09/24
 */
export const UserManageTable = ({
  openBy,
}: {
  openBy: (type: string, context?: GO) => void;
}) => {
  const userQuery = useUsers();
  const getDay = (createdAt: string) => createdAt.split('T')[0];
  const orderedUser = orderBy(userQuery.data, ['date_created'], ['desc']);
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Username</th>
          <th>Email</th>
          <th>Company</th>
          <th>Role</th>
          <th>Created At</th>
          <th>Operation</th>
        </tr>
      </thead>
      <tbody>
        {orderedUser.map((usr) => (
          <tr key={usr.id}>
            <td>{usr.id}</td>
            <td>{usr.name}</td>
            <td>{usr.email}</td>
            <td>?</td>
            <td>?</td>
            <td>{getDay(`${usr.date_created}`)}</td>
            <td>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm me-2"
              >
                Set Company
              </button>
              <button
                type="button"
                disabled
                className="btn btn-outline-danger btn-sm"
                onClick={() =>
                  openBy(MDT.DELETE_USER, {
                    userId: usr.id,
                    userName: usr.name,
                  })
                }
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
