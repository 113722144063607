import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL, TK_AWS_API_URL } from '../config';
import { useNotificationStore } from '../stores/notifications';
import storage from '../utils/storage';

import { HTTP_CODE, AUTHERRORCODES, TOKENINVALIDCODES } from './http';

export const axios = Axios.create({
  baseURL: API_URL + '/v1/',
});

export const downloadFile = (url: string) => {
  return axios.get(url, {
    responseType: 'blob', // important
  });
};

/**
 * Specify `devToken` & `baseURL` with TK_AWS_API_URL
 *
 * @deprecated
 * @returns axios client
 */
export const getProjectClient = () => {
  const authRequestInterceptor = (config: AxiosRequestConfig) => {
    const devToken = process.env.REACT_APP_API_TOKEN_DEV;
    const token = devToken || storage.getToken();
    if (token) {
      config.headers.authorization = token;
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers['x-access-token'] = token;
    config.headers.Accept = 'application/json';
    return config;
  };
  axios.interceptors.request.use(authRequestInterceptor);
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.baseURL = TK_AWS_API_URL;
  return axios;
};

/**
 * == NOT IN USE ==
 * Legacy method to get axios instance for `project` module
 * @deprecated
 * @returns axios client
 */
export const resetBaseURL = () => {
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.baseURL = TK_AWS_API_URL;
  return axios;
};

/**
 * == Commonly used axios client in ADMIN-PANEL APP ====
 * @returns axios client with baseURL setup
 */
export const restoreURL = () => {
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.baseURL = API_URL;
  return axios;
};

export const uploadUserURL = () => {
  axios.defaults.headers['Content-Type'] = 'multipart/form-data';
  axios.defaults.baseURL = API_URL;
  return axios;
};

/**
 * Upload blueprint pdf file
 *
 * @returns axios client
 */
export const uploadURL = () => {
  axios.defaults.headers['Content-Type'] = 'multipart/form-data';
  axios.defaults.baseURL = TK_AWS_API_URL;
  return axios;
};

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    config.headers['x-access-token'] = token;
  }
  config.headers['Content-Type'] = 'application/json';
  config.headers.Accept = 'application/json';
  return config;
}

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const code = error.response?.status as number;
    console.error(`### got network error: ${code} ###`);
    const isAuthError = AUTHERRORCODES.includes(code);
    const isTokenInvalid = TOKENINVALIDCODES.includes(code);
    if (isAuthError) {
      // console.warn('## got auth error to clear token ....');
      isTokenInvalid && storage.clearToken();
      // === decouple error handling the logic from low level tool
      // @2023/07/22
      const eventType = HTTP_CODE[code].event;
      document.dispatchEvent(new Event(eventType));
      // FIXME: throw error in case of token error which could be 440
      // @2023/09/07
      return Promise.reject(error);
    }

    const errorDetail = error.response?.data?.detail; // this is what our system return
    const errorMessage = error.response?.data?.message; // or use message property
    const defaultError = error.message;
    const message = errorDetail || errorMessage || defaultError;
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Error',
      message,
    });

    return Promise.reject(error);
  }
);
