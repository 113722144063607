import { ROLES } from '@/lib/authorization';
import { BaseEntity } from '@/types';

/**
 * query invitations for company
 */
export const INVITATION_QUERY_KEY = 'invitations';

/**
 * query key for users of companay.
 */
export const CONTRIBUTOR_QUERY_KEY = 'contributors';
/**
 * query key for company CRUD
 */
export const COMPANY_QUERY_KEY = 'companies';

/**
 * Invitation State from backend
 */
export type Invitation = {
  /** USER_CONFIRMATION */
  action: string;
  /** 1 | 0 */
  completed: number;
  email: string;
  expires_on: string;
  invite_company_id: number;
  invite_role_id: number;
  token: string;
};

export type Company = {
  phone: string;
  state: string;
  created_by: string;
  modified_by: string;
  name: string;
  country_code: string;
  date_created: string;
  date_modified: string;
  country: {
    code: string;
    name: string;
  };
  status: string;
} & BaseEntity;

export type Contributor = {
  company: {
    id: string;
    name: string;
  };
  user: {
    id: string;
    email: string;
    name: string;
  };
  role: {
    id: number;
    name: string;
    role_type: string;
    permissions: unknown[];
  };
} & Omit<BaseEntity, 'id'>;

export type ContributorField = {
  email: string;
  role_id: string;
};

type Permission = {
  name: string;
  id: number;
} & BaseEntity;

export type Role = {
  name: ROLES;
  default_company_role: number;
  default_project_role: number;
  role_type: ROLES;
  permissions: Permission[];
} & BaseEntity;
