import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { useUsers, User } from '@/features/users';
import { restoreURL } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { ContributorField, CONTRIBUTOR_QUERY_KEY } from '../types';

export type AddContributorDTO = {
  company_id: string;
  user_id: string;
  role_id: string;
};

type AddContributorsParams = {
  company_id: string;
  users?: User[];
  contributorFields: ContributorField[];
};

/**
 * Legacy method to allocate a newly registerd user to a company.
 *
 * @returns
 */
export const addContributorsByEmail = ({
  company_id,
  users,
  contributorFields,
}: AddContributorsParams) => {
  const findUserByEmail = (email: string) => {
    return users?.find((user) => user.email === email);
  };

  const payload = contributorFields.reduce((acc, contributor) => {
    // must be a registerd user in system to add to a company!
    const user_id = findUserByEmail(contributor.email)?.id;

    if (!user_id) {
      console.warn(`## User not found in system: ${contributor.email}`);
      return acc;
    }

    acc.push({
      company_id: company_id,
      user_id,
      role_id: contributor.role_id,
    });

    return acc;
  }, [] as AddContributorDTO[]);

  if (!payload.length) {
    console.warn(`## No contributor data to send!`);
    throw new Error('No available user found');
  }

  return restoreURL().post(`${API_CTX}/companies/${company_id}/users`, payload);
};

type UseAddContributorsByEmailOptions = {
  companyId: string | number;
  config?: MutationConfig<typeof addContributorsByEmail>;
};

/**
 * Used in `AddContributorsModal`
 * @param param0
 * @returns
 */
export const useAddContributorsByEmail = ({
  companyId,
  config,
}: UseAddContributorsByEmailOptions) => {
  const { addNotification } = useNotificationStore();
  const usersQuery = useUsers();
  return useMutation({
    ...config,
    onError: (error) => {
      addNotification({
        type: 'error',
        title: 'Error adding Contributors',
        message: error.response?.data.detail,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([CONTRIBUTOR_QUERY_KEY, companyId]);
      addNotification({
        type: 'success',
        title: 'Contributors Added',
      });
    },
    mutationFn: (params) =>
      addContributorsByEmail({ ...params, users: usersQuery.data }),
  });
};
