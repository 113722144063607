import { omit, omitBy } from 'lodash';
import { useMutation } from 'react-query';

import { AuthUser } from '@/features/auth';
import { restoreURL } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export type UpdateUserDTO = {
  id?: string;
  data: Partial<AuthUser & { password: string }>;
};

export const updateUser = ({ id, data }: UpdateUserDTO) => {
  return restoreURL().patch(`/v1/users/${id}`, data);
};

type UseUpdateUserOptions = {
  userId: string;
  onSuccess?: (...args: any[]) => void;
  config?: MutationConfig<typeof updateUser>;
};

export const useUpdateUser = ({
  userId,
  config,
  onSuccess,
}: UseUpdateUserOptions) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      if (onSuccess) {
        onSuccess?.();
      } else {
        addNotification({
          type: 'success',
          title: 'User Updated',
        });
      }
    },
    ...config,
    mutationFn: (params) => {
      const data = omitBy(params.data, (v) => v === '');

      // TODO
      // return updateUser({ id: userId, data });
      return updateUser({ id: userId, data: omit(data, ['notifications']) });
    },
  });
};
