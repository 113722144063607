import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';

import toast from 'react-hot-toast';

export const deleteProperty = (name: string) => {
  return restoreURL().delete(`${API_CTX}/properties/${name}`);
};

export const useDeleteProperty = ({
  name,
  onSuccess,
}: {
  name: string;
  onSuccess?: () => void;
}) => {
  return useMutation({
    onSuccess: () => {
      toast.success('The property was deleted successfully');
      queryClient.invalidateQueries(['properties']);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
    mutationFn: () => {
      return deleteProperty(name);
    },
  });
};
