import axios from 'axios';

import { API_URL, API_CTX } from '@/config';

export const requestPasswordReset = (data: { email: string }) => {
  return axios.request({
    baseURL: API_URL,
    url: `${API_CTX}/password-reset/request`,
    method: 'post',
    data,
  });
};
