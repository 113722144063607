import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import { useAddProperty } from '../../../features/properties/api';

import { PROPERTY_TYPE } from '../../../features/items/options';

type SystemPropertiesAddModalProps = {
  show: boolean;
  handleClose: () => void;
};

const SystemPropertiesAddModal = ({
  show,
  handleClose,
}: SystemPropertiesAddModalProps) => {
  const initialPayload = {
    name: '',
    label: '',
    data_type: PROPERTY_TYPE.S,
  };

  const [payload, setPayload] = useState(initialPayload);

  const addProperty = useAddProperty({
    onSuccess: () => {
      handleClose();
      clearPayload();
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await addProperty.mutateAsync({ payload });
  };

  const clearPayload = () => {
    setPayload(initialPayload); // Reset payload to its initial state
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={() => {
        handleClose();
        clearPayload();
      }}
    >
      <Modal.Header>
        <Modal.Title className="h6">Add Property</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={() => {
            handleClose();
            clearPayload();
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.name || ''}
                name="name"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Label</Form.Label>
            <InputGroup>
              <InputGroup>
                <Form.Control
                  value={payload.label || ''}
                  name="label"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <InputGroup>
              <Form.Select
                value={payload.data_type || ''}
                name="data_type"
                onChange={handleSelectChange}
              >
                {Object.values(PROPERTY_TYPE).map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <div className="d-flex justify-content-start gap-3">
            <Button variant="light" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="secondary" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SystemPropertiesAddModal;
