import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import { PROPERTY_TYPE } from '../../../features/items/options';
import { Property } from '../../../types';
import {
  useDeleteProperty,
  useUpdateProperty,
} from '../../../features/properties/api';

type PropertiesModalProps = {
  show: boolean;
  handleClose: () => void;
  selectedItem?: Property;
  showConstantPropertiesModal: () => void;
};

const PropertiesModal = ({
  show,
  handleClose,
  selectedItem,
  showConstantPropertiesModal,
}: PropertiesModalProps) => {
  const initialPayload = {
    created_by: '',
    data_type: undefined,
    date_created: '',
    label: '',
    last_modified_by: '',
  };

  const { created_by, data_type, date_created, label, name, last_modified_by } =
    selectedItem || {};

  const [payload, setPayload] = useState({
    created_by,
    data_type,
    date_created,
    label,
    last_modified_by,
  });

  const updateProperty = useUpdateProperty(name as string, () => {
    handleClose();
    clearPayload();
  });

  const deleteProperty = useDeleteProperty({ name: name as string });

  const clearPayload = () => {
    setPayload(initialPayload);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await updateProperty.mutateAsync({ payload });
  };

  useEffect(() => {
    setPayload({
      created_by,
      data_type,
      date_created,
      label,
      last_modified_by,
    });
  }, [created_by, data_type, date_created, label, last_modified_by]);

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Edit Properties</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Created by</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.created_by || ''}
                name="created_by"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Date type</Form.Label>
            <InputGroup>
              <Form.Select
                value={payload.data_type || ''}
                name="data_type"
                onChange={handleSelectChange}
              >
                {Object.values(PROPERTY_TYPE).map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Date created</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.date_created || ''}
                name="date_created"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Label</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.label || ''}
                name="label"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Modified By</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.last_modified_by || ''}
                name="last_modified_by"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <Button variant="light" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="secondary" type="submit">
                Save
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  deleteProperty.mutateAsync();
                }}
              >
                Delete
              </Button>
            </div>
            <Button onClick={showConstantPropertiesModal} variant="secondary">
              Show constant-property
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PropertiesModal;
