import { ModalOpenProps } from '@/components/Modals/modal';
import { Modal, Button, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { Company } from '../types';

import { useInvitationAPI } from '../hooks/useInvitationAPI';

export const InviteUserModal = ({
  isOpen,
  closeModal,
  companyProfile,
}: ModalOpenProps & { companyProfile: Company }) => {
  const { id } = companyProfile;

  const {
    email,
    sending,
    roleOptions,
    handleSubmit,
    emailChangeHandler,
    roleChangeHandler,
  } = useInvitationAPI(id, closeModal);

  return (
    <Modal show={isOpen} onHide={() => closeModal({})} title="Invite User">
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">
          Invite User | {companyProfile.name}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex justify-center">
        {/* invitation form with email only! */}
        <Form id="invite-user" onSubmit={handleSubmit} className="w-75">
          <Form.Group className="mb-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              onChange={emailChangeHandler}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>User Role</Form.Label>
            <Form.Select
              aria-label="role selection"
              onChange={roleChangeHandler}
            >
              {roleOptions.map((rl) => (
                <option key={rl.value} value={rl.value}>
                  {rl.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-end gap-3">
            <Button variant="light" onClick={() => closeModal({})}>
              Cancel
            </Button>
            {/* Send Invitation */}
            <Button
              variant="success"
              type="submit"
              disabled={!email || sending}
            >
              {sending && (
                <>
                  <Spinner size="sm" animation="border" />
                  Sending ......
                </>
              )}
              {!sending && `Send Invitation`}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
