import { useQuery } from 'react-query';

import { restoreURL } from '@/lib/axios';
import { API_CTX } from '@/config';
import { Item } from '@/types';

export const getItems = async () => {
  return restoreURL()
    .get(`${API_CTX}/items`)
    .then((response) => {
      return response.data.documents;
    });
};

export const useItems = () => {
  return useQuery<Partial<Item[]>, Error>({
    queryKey: ['items'],
    queryFn: getItems,
  });
};
