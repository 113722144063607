import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { CONTRIBUTOR_QUERY_KEY } from "../types";

type ChangeContributorRoleParams = {
  companyId: string;
  userId: string;
  prevRoleId: number;
  nextRoleId: number;
};

export const changeContributorRole = ({
  companyId,
  userId,
  prevRoleId,
  nextRoleId,
}: ChangeContributorRoleParams) => {
  const roleEndpoint = `${API_CTX}/companies/${companyId}/users/${userId}/roles`;

  return Promise.all([
    restoreURL().post(`${roleEndpoint}/${nextRoleId}`),
    restoreURL().delete(`${roleEndpoint}/${prevRoleId}`),
  ]);
};

type UseChangeContributorRoleOptions = {
  config?: MutationConfig<typeof changeContributorRole>;
};

export const useChangeContributorRole = ({
  config,
}: UseChangeContributorRoleOptions) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousContributors) {
        queryClient.setQueryData(CONTRIBUTOR_QUERY_KEY, context.previousContributors);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(CONTRIBUTOR_QUERY_KEY);
      addNotification({
        type: 'success',
        title: 'Contributor Role Changed',
      });
    },
    ...config,
    mutationFn: changeContributorRole,
  });
};
