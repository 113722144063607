import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React from 'react';

// import { requestPasswordReset } from '@/features/auth';

import { useRemoveContributor } from '../api/removeContributor';
import { Contributor } from '../types';

interface ContributorMenuProps {
  contributor: Contributor;
}

export const ContributorMenu = ({ contributor }: ContributorMenuProps) => {
  const menuItemClass = 'btn block py-2 text-sm text-left text-gray-700 fw-150';

  // const handleSendPasswordReset = async () => {
  //   await requestPasswordReset({
  //     email: contributor.user.email,
  //   });
  // };

  const removeContributorMutation = useRemoveContributor({
    companyId: contributor.company.id,
  });

  const handleRemoveFromGroup = async () => {
    await removeContributorMutation.mutateAsync({
      userId: contributor.user.id,
      roleId: contributor.role.id,
    });
  };

  return (
    <Menu as="div" className="relative mt-4">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="p-0 border border-0 bg-light bg-body">
              <EllipsisVerticalIcon className="w-6 h-6 text-primary" />
              <span className="sr-only">Open contributor menu</span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="border absolute right-4 top-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
            >
              {/* <Menu.Item>
                {({ active }) => (
                  <button
                    className={clsx(active && 'bg-gray-100', menuItemClass)}
                    onClick={handleSendPasswordReset}
                  >
                    Send Password Reset
                  </button>
                )}
              </Menu.Item> */}
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={clsx(active && 'bg-gray-100', menuItemClass)}
                    onClick={handleRemoveFromGroup}
                  >
                    Remove a User
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
