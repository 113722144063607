import React from 'react';
import { initReactQueryAuth } from 'react-query-auth';

import {
  AuthUser,
  getUser,
  LoginCredentialsDTO,
  loginWithEmailAndPassword,
  UserResponse,
} from '../features/auth';
import storage from '../utils/storage';

export async function handleUserResponse(data: UserResponse) {
  const { token, ...user } = data;
  if (token) {
    // update token in browser cache
    storage.setToken(token);
    storage.setLoginTime();
  }
  return user;
}

/**
 * Fetch user info with cached token, result could be `null` if token expired.
 * Stronger way to load user through token, with `try...catch`.
 *
 * @returns user | null
 */
async function loadUser() {
  // check token expiry first
  const isExpired = storage.isTokenExpired();
  if (isExpired) {
    const lastLogin = storage.getLoginTime();
    console.warn(`### using expired token, last login time is: ${lastLogin}`);
    // if expired detected, return null
    return null;
  }
  if (storage.getToken()) {
    // FIXME: trying to get logged in user info with a cached token ...
    // null returned, if token is expired
    // @2023/09/07
    try {
      const response = await getUser();
      return await handleUserResponse(response);
    } catch (error) {
      console.error(`## got error on fetching user!!!`);
      return null;
    }
  }
  return null;
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);
  return await handleUserResponse(response);
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin);
}

const authConfig = {
  loadUser,
  loginFn,
  logoutFn,
  registerFn: async () => {
    throw new Error('Registration is not supported');
  },
  LoaderComponent() {
    return <div></div>;
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  unknown,
  LoginCredentialsDTO
>(authConfig);
