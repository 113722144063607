import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { CONTRIBUTOR_QUERY_KEY } from '../types';

type RemoveContributorParams = {
  companyId?: string | number;
  userId: string | number;
  roleId: string | number;
};

export const removeContributor = ({
  companyId,
  userId,
  roleId,
}: RemoveContributorParams) => {
  return restoreURL().delete(
    `${API_CTX}/companies/${companyId}/users/${userId}/roles/${roleId}`
  );
};

type UseRemoveContributorOptions = {
  companyId: string | number;
  config?: MutationConfig<typeof removeContributor>;
};

export const useRemoveContributor = ({
  companyId,
  config,
}: UseRemoveContributorOptions) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousContributors) {
        queryClient.setQueryData(
          CONTRIBUTOR_QUERY_KEY,
          context.previousContributors
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([CONTRIBUTOR_QUERY_KEY, companyId]);
      addNotification({
        type: 'success',
        title: 'Contributor Removed',
      });
    },
    ...config,
    mutationFn: (params) => removeContributor({ ...params, companyId }),
  });
};
