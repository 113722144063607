import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';
import { filterOutFalsyValues } from '@/lib';

interface Payload {
  created_by?: string | null;
  data_type?: 'STRING' | 'NUMBER';
  label?: string;
  last_modified_by?: string | null;
}

export const updateProperty = ({
  propertyName,
  payload,
}: {
  propertyName: string;
  payload: Payload;
}) => {
  const data = filterOutFalsyValues(payload);

  return restoreURL().patch(`${API_CTX}/properties/${propertyName}`, data);
};

export const useUpdateProperty = (
  propertyName: string,
  onSuccess?: () => void
) => {
  return useMutation({
    onSuccess: () => {
      toast.success(`The property was updated successfully`);
      queryClient.invalidateQueries(['properties']);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      toast.error(`An error occurs: ${error.message}`);
    },
    mutationFn: ({ payload }: { payload: Payload }) => {
      return updateProperty({ propertyName, payload });
    },
  });
};
