import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import {
  useDeleteItemProperty,
  useEditItemProperty,
} from '../../../features/item-properties/api';

import { Item } from '../../../types';

type ItemPropertiesEditModalProps = {
  show: boolean;
  handleClose: () => void;
  handleBack: () => void;
  itemId?: string;
  selectedItemProperty?: Item['properties'][0];
};

const ItemPropertiesEditModal = ({
  show,
  handleClose,
  handleBack,
  itemId,
  selectedItemProperty,
}: ItemPropertiesEditModalProps) => {
  const {
    metric_lower_bound,
    metric_upper_bound,
    imperial_lower_bound,
    imperial_upper_bound,
    property_name,
  } = selectedItemProperty || {};

  const initialPayload = {
    metric_lower_bound: 0,
    metric_upper_bound: 0,
    imperial_lower_bound: 0,
    imperial_upper_bound: 0,
  };

  const [payload, setPayload] = useState(initialPayload);

  const editItemProperty = useEditItemProperty({
    onSuccess: () => {
      handleClose();
    },
  });

  const deleteItemProperty = useDeleteItemProperty({
    onSuccess: () => {
      handleClose();
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: +value }));
  };

  const handleDelete = async () => {
    if (!itemId || !property_name) return;

    await deleteItemProperty.mutateAsync({
      id: itemId,
      name: property_name,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!itemId || !property_name) return;

    event.preventDefault();
    await editItemProperty.mutateAsync({
      id: itemId,
      name: property_name,
      payload,
    });
  };

  useEffect(() => {
    if (
      !metric_lower_bound ||
      !metric_upper_bound ||
      !imperial_lower_bound ||
      !imperial_upper_bound
    )
      return;

    setPayload({
      metric_lower_bound,
      metric_upper_bound,
      imperial_lower_bound,
      imperial_upper_bound,
    });
  }, [
    metric_lower_bound,
    metric_upper_bound,
    imperial_lower_bound,
    imperial_upper_bound,
  ]);

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Edit item-properties</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Metric Lower Bound</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.metric_lower_bound || ''}
                name="metric_lower_bound"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Metric Upper Bound</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.metric_upper_bound || ''}
                name="metric_upper_bound"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Imperial Lower Bound</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.imperial_lower_bound || ''}
                name="imperial_lower_bound"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Imperial Upper Bound</Form.Label>
            <InputGroup>
              <Form.Control
                value={payload.imperial_upper_bound || ''}
                name="imperial_upper_bound"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <div className="d-flex justify-content-between mt-3">
            <Button onClick={handleBack} variant="light">
              Go back
            </Button>
            <div className="d-flex gap-4">
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
              <Button type="submit" variant="secondary">
                Edit
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ItemPropertiesEditModal;
