import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';
import { filterOutFalsyValues } from '@/lib';

interface ConstantProp {
  acronym: string;
  category: string;
  color_code: string;
  description: string;
  position: number;
}

type Error = {
  message: string;
  response: {
    data: {
      detail?: string;
    };
  };
};

export const editConstantProperty = ({
  name,
  payload,
}: {
  name: string;
  payload: ConstantProp;
}) => {
  const data = filterOutFalsyValues(payload);

  return restoreURL().patch(`${API_CTX}/properties/${name}/constants`, data);
};

export const useEditConstantProperty = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['properties']);
      toast.success('The constant-property was edited successfully');

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      const errorDetail = error.response.data.detail;
      toast.error(
        `An error occurs: ${errorDetail ? errorDetail : error.message}`
      );
    },
    mutationFn: ({
      name,
      payload,
    }: {
      name: string;
      payload: ConstantProp;
    }) => {
      return editConstantProperty({ name, payload });
    },
  });
};
