export const Routes = {
  // pages
  SignIn: { path: '/' },
  ForgotPassword: { path: '/forgot-password' },
  ResetPassword: { path: '/reset-password' },
  HomeScreen: { path: '/home-page' },
  JobStatusTracking: { path: '/job-status-tracking' },
  ItemCatalogue: { path: '/item-catalogue' },
  CompanyProfiles: { path: '/company-profiles' },
  Transactions: { path: '/transactions' },
  Settings: { path: '/settings' },
  NotFound: { path: '/examples/404' },
};
