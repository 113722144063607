import { useQuery } from 'react-query';
import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';

import { Company, COMPANY_QUERY_KEY } from '../types';

/**
 * Need to declare returned promise type, or cause ts complaint!
 * @returns company list
 */
export const getCompanies = (): Promise<Company[]> => {
  return restoreURL().get(`${API_CTX}/companies`);
};

export const useCompanies = () => {
  return useQuery<Company[], Error>(COMPANY_QUERY_KEY, getCompanies);
};
