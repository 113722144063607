import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';
import { filterOutFalsyValues } from '@/lib';

interface EditItemPropertyPayload {
  metric_lower_bound: number;
  metric_upper_bound: number;
  imperial_lower_bound: number;
  imperial_upper_bound: number;
}

type Error = {
  message: string;
  response: {
    data: {
      detail?: string;
    };
  };
};

export const editItemProperty = ({
  id,
  name,
  payload,
}: {
  id: string;
  name: string;
  payload: EditItemPropertyPayload;
}) => {
  const data = filterOutFalsyValues(payload);

  return restoreURL().patch(`${API_CTX}/items/${id}/properties/${name}`, data);
};

export const useEditItemProperty = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['properties']);
      toast.success('The constant-property was edited successfully');

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      const errorDetail = error.response.data.detail;
      toast.error(
        `An error occurs: ${errorDetail ? errorDetail : error.message}`
      );
    },
    mutationFn: ({
      id,
      name,
      payload,
    }: {
      id: string;
      name: string;
      payload: EditItemPropertyPayload;
    }) => {
      return editItemProperty({ id, name, payload });
    },
  });
};
