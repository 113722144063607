import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';

import { UserResponse } from '../types';

export type PasswordResetDTO = {
  password: string;
  email: string;
  token: string;
  email_confirmed: number;
};

export const passwordReset = (
  data: PasswordResetDTO
): Promise<UserResponse> => {
  return restoreURL().post(
    `${API_CTX}/password-reset/reset?token=${data.token}`,
    data
  );
};
