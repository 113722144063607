import { startCase, toLower } from 'lodash';
import { useQuery } from 'react-query';

import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Role } from '../types';

const getRoleOptions = (roles?: Role[]) =>
  (roles || []).map((role) => ({
    label: startCase(toLower(role.name)),
    value: role.id,
  }));

export const getRoles = (): Promise<Role[]> => {
  return restoreURL().get(`${API_CTX}/roles`);
};

type QueryFnType = typeof getRoles;

type UseCompaniesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useRoles = ({ config }: UseCompaniesOptions) => {
  const queryResults = useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['roles'],
    queryFn: getRoles,
    staleTime: 60 * 1000, // 1 minute
    ...config,
  });

  return { ...queryResults, roleOptions: getRoleOptions(queryResults.data) };
};
