import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';
import { filterOutFalsyValues } from '@/lib';

interface ItemPropertyPayload {
  property_name: string;
  metric_lower_bound: number;
  metric_upper_bound: number;
  imperial_lower_bound: number;
  imperial_upper_bound: number;
}

export const addItemProperty = async (
  id: string,
  payload: ItemPropertyPayload
) => {
  const data = filterOutFalsyValues(payload);

  await restoreURL().post(`${API_CTX}/items/${id}/properties`, data);
};

export const useAddItemProperty = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  return useMutation<void, Error, { id: string; payload: ItemPropertyPayload }>(
    ({ id, payload }) => addItemProperty(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['items']);
        toast.success(`The item-property has been added successfully`);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (error: Error) => {
        toast.error(`An error occurs: ${error.message}`);
      },
    }
  );
};
