import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';

type Error = {
  message: string;
  response: {
    data: {
      detail?: string;
    };
  };
};

export const deleteItemProperty = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  return restoreURL().delete(`${API_CTX}/items/${id}/properties/${name}`);
};

export const useDeleteItemProperty = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['properties']);
      toast.success('The constant-property was deleted successfully');

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      const errorDetail = error.response.data.detail;
      toast.error(
        `An error occurs: ${errorDetail ? errorDetail : error.message}`
      );
    },
    mutationFn: ({ id, name }: { id: string; name: string }) => {
      return deleteItemProperty({ id, name });
    },
  });
};
