import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { queryClient } from '@/lib/react-query';
import { restoreURL } from '@/lib/axios';

import toast from 'react-hot-toast';

export const deleteUser = (userId: string) => {
  return restoreURL().delete(`${API_CTX}/users/${userId}`);
};

export const useDeleteUser = ({ onSuccess }: { onSuccess?: () => void }) => {
  return useMutation({
    onSuccess: () => {
      toast.success('The user was deleted successfully');
      queryClient.invalidateQueries(['users']);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
    mutationFn: (userId: string) => {
      return deleteUser(userId);
    },
  });
};
