import { sortBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHourglassStart,
  faHourglassEnd,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { useInvitations } from '../api/getInvitations';
import { Invitation } from '../types';
import { sendInvitationBy } from '../hooks/useInvitationAPI';

type UDFInvitations = Invitation[] | undefined;

/**
 * Invitation list for current company
 * @date 2024/09/12
 */
export const InvitationList = ({ companyId }: { companyId: string }) => {
  const queryResult = useInvitations({ companyId });
  const invitations = (queryResult.data as UDFInvitations) || [];
  // older first, newer last
  const invitationsByDate = sortBy(invitations, ['expires_on']);

  const isExpired = (ivt: Invitation) => {
    return new Date() > new Date(ivt.expires_on);
  };

  // get a final status for each email
  const uniqueEmails = invitationsByDate.reduce(
    (prev, curr) => {
      const noExist = prev[curr.email] === undefined;
      if (noExist) {
        prev[curr.email] = curr.completed;
      } else if (curr.completed) {
        prev[curr.email] = 1; // mark completed
      }
      return prev;
    },
    {} as { [email: string]: number }
  );
  // get email by `completed` = 0
  const uncompletedEmail = Object.keys(uniqueEmails).reduce((prev, curr) => {
    // check `completed` = 0
    if (!uniqueEmails[curr]) {
      // get un-completed
      prev.push(curr);
    }
    return prev;
  }, [] as string[]);

  const reducedInvitation = uncompletedEmail.map((eml) => {
    // find the latest invitation!
    const newerFirstInvites = [...invitationsByDate].reverse();
    return newerFirstInvites.find(
      (i: Invitation) => i.email === eml
    ) as Invitation;
  });

  const hasInvites = reducedInvitation.length > 0;

  const resendInvitationHandler = (
    email: string,
    company: number,
    role: number
  ) => {
    sendInvitationBy(email, company, role);
    toast.success(`Invitation resend success!`);
  };

  return (
    <div className="invitation-list pt-2">
      {hasInvites && (
        <h6 className="mt-2 text-underline">Invitations to confirm:</h6>
      )}
      {hasInvites && (
        <ul className="w-50 ps-2">
          {reducedInvitation.map((ivt, i) => (
            <li
              key={i}
              className="d-flex justify-content-between align-items-center mt-1 py-1 border-bottom"
            >
              <span className={isExpired(ivt) ? 'text-through' : ''}>
                {ivt.email}
              </span>
              <div>
                <Button
                  size="sm"
                  variant="outline-danger"
                  className="me-3"
                  onClick={() => {
                    const {
                      email,
                      invite_company_id: company,
                      invite_role_id: role,
                    } = ivt;
                    resendInvitationHandler(email, company, role);
                  }}
                >
                  Resend Invitation
                </Button>
                {isExpired(ivt) ? (
                  <FontAwesomeIcon icon={faHourglassEnd} size="sm" />
                ) : (
                  <FontAwesomeIcon icon={faHourglassStart} size="sm" />
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
