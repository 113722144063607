import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { passwordReset } from '../api/passwordReset';
import { UserResponse } from '../types';

import { useAuth, handleUserResponse } from "@/lib/auth";

type PasswordResetValues = {
  password: string;
  confirmPassword: string;
};

export const useResetPasswordAPI = () => {
  const { login } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const email = searchParams.get('email')?.replace(' ', '+');
  const token = searchParams.get('token');

  const onFormSubmit = useCallback(
    async ({ password }: PasswordResetValues) => {
      if (!email || !token) {
        console.error('Invalid data in search params', { email, token });

        return;
      }

      setIsLoading(true);

      const parsedData = {
        email,
        token,
        password,
        email_confirmed: 1,
      };

      try {
        await passwordReset(parsedData);

        /* NOTE react-query-auth does not provide the ability to add a callback for password reset
        so we need to log in to handle the user */
        const response = await login({ email, password });

        return await handleUserResponse(response as UserResponse);
      } catch (e) {
        console.log(e);
      }
    },
    [email, login, token]
  );

  return { isLoading, onFormSubmit };
};
