import { useQuery } from 'react-query';

import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Contributor, CONTRIBUTOR_QUERY_KEY } from '../types';

export const getContributors = ({
  companyId,
}: {
  companyId: string;
}): Promise<Contributor[]> => {
  return restoreURL().get(`${API_CTX}/companies/${companyId}/users`);
};

type QueryFnType = typeof getContributors;

type UseContributorsOptions = {
  companyId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useContributors = ({
  companyId,
  config,
}: UseContributorsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [CONTRIBUTOR_QUERY_KEY, companyId],
    queryFn: () => getContributors({ companyId }),
    enabled: !!companyId,
    ...config,
  });
};
