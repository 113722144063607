import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Col } from 'react-bootstrap';

import { Routes } from '../../../routes';

import LogoImage from '../../../assets/TaksoAI_logo.svg';

interface AuthLayoutProps {
  title: string;
  children: ReactNode;
  backButton: boolean;
}

const AuthLayout = ({ title, children, backButton }: AuthLayoutProps) => {
  const history = useHistory();

  return (
    <main>
      <section className="d-flex align-items-center vh-100">
        <Col md={5} xs={12} className="h-100">
          {backButton && (
            <div
              onClick={() => {
                history.push(Routes.SignIn.path);
              }}
              className="position-absolute left-4 top-4 icon icon-shape icon-sm rounded bg-gray-400 shadow-md cursor-pointer"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
          )}
          <div className="bg-white shadow-soft border rounded border-light pt-7 px-6 h-100 w-100">
            <div className="text-center text-md-center mb-4 mt-md-0">
              <h2 className="fs-4 mb-0">{title}</h2>
            </div>
            {children}
          </div>
        </Col>
        <Col
          className="d-none d-md-flex flex-column align-items-center justify-content-center"
          sm={7}
        >
          <h2 style={{ fontWeight: 400 }} className="fs-4 mb-5">
            Admin Tool
          </h2>
          <img style={{ height: '384px' }} src={LogoImage} alt="Logo" />
        </Col>
      </section>
    </main>
  );
};

export default AuthLayout;
