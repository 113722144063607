import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { ReactComponent as EyeCloseIcon } from '@/assets/icons/eye-close.svg';
import { ReactComponent as EyeOpenIcon } from '@/assets/icons/eye-open.svg';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  placeholder?: string;
  labelStyles?: string;
  wrapperStyles?: string;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = 'text',
    label,
    className,
    registration,
    error,
    placeholder,
    labelStyles,
    wrapperStyles,
  } = props;

  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const toggleEye = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setIsPasswordHidden((prevState) => !prevState);
    },
    []
  );

  const inputType = isPasswordHidden ? 'password' : 'text';

  return (
    <FieldWrapper
      label={label}
      error={error}
      className={labelStyles}
      wrapperStyles={wrapperStyles}
    >
      <input
        type={type === 'password' ? inputType : type}
        className={clsx(
          `${type === 'password' ? 'pl-3 pr-8' : 'px-3'}`,
          'appearance-none block px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
          className
        )}
        placeholder={placeholder}
        {...registration}
      />
      {type === 'password' ? (
        <button
          type="button"
          className="h-6 absolute right-3 top-1.5 cursor-pointer focus:outline-none"
          onClick={toggleEye}
          tabIndex={-1}
        >
          {isPasswordHidden ? <EyeCloseIcon /> : <EyeOpenIcon />}
        </button>
      ) : null}
    </FieldWrapper>
  );
};
