import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Routes } from '../routes';

// pages
import SignIn from './SignIn';
import HomeScreen from './HomeScreen';
import JobStatusTracking from './JobStatusTracking';
import ItemCatalogue from './ItemCatalogue';
import CompanyProfiles from './CompanyProfiles';

// components
import Preloader from '../components/Preloader';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import { useTokenErrorHandler } from '../lib/useTokenErrorHandler';

import { Toaster } from 'react-hot-toast';

interface RouteWithLoaderProps {
  component: React.ComponentType<Record<string, unknown>>;
  path: string;
  exact: boolean;
}

const RouteWithLoader = ({
  component: Component,
  ...rest
}: RouteWithLoaderProps) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
        </>
      )}
    />
  );
};

const App: React.FC = () => {
  // useTokenErrorHandler();

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Switch>
        <RouteWithLoader exact path={Routes.SignIn.path} component={SignIn} />
        <RouteWithLoader
          exact
          path={Routes.ForgotPassword.path}
          component={ForgotPassword}
        />
        <RouteWithLoader
          exact
          path={Routes.ResetPassword.path}
          component={ResetPassword}
        />
        <RouteWithLoader
          exact
          path={Routes.HomeScreen.path}
          component={HomeScreen}
        />
        <RouteWithLoader
          exact
          path={Routes.JobStatusTracking.path}
          component={JobStatusTracking}
        />
        <RouteWithLoader
          exact
          path={Routes.ItemCatalogue.path}
          component={ItemCatalogue}
        />
        <RouteWithLoader
          exact
          path={Routes.CompanyProfiles.path}
          component={CompanyProfiles}
        />
        <Redirect to={Routes.NotFound.path} />
      </Switch>
    </>
  );
};

export default App;
