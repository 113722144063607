import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import TableSwitcher from '../components/TableSwitcher';
import {
  ItemCatalogueTable,
  SystemPropertiesTable,
} from '../components/Tables';
import {
  ItemCatalogueAddModal,
  PropertiesAddModal,
} from '../components/Modals';
import { Container, Col, Row } from 'react-bootstrap';
import { useProperties } from '../features/properties/api';
import { useItems } from '../features/items/api';

const ItemCatalogue = () => {
  const [activeTab, setActiveTab] = useState('table1');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const itemsQuery = useItems();
  const propertiesQuery = useProperties();

  return (
    <>
      {activeTab === 'table1' && (
        <ItemCatalogueAddModal
          show={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
      {activeTab === 'table2' && (
        <PropertiesAddModal
          show={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
      <Container>
        <Row>
          <Col className="bg-dark sidebar d-flex flex-column">
            <Navbar activePage="catalogue" />
          </Col>

          <Col className="mx-4">
            <div
              className="d-flex flex-column align-items-center mt-4"
              style={{ height: '100vh' }}
            >
              <h1 className="text-center">Item Properties</h1>
              <div
                className="d-flex gap-3 mt-4"
                style={{ width: 'fit-content' }}
              >
                <TableSwitcher
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                {activeTab === 'table1' && (
                  <button
                    className="btn btn-primary"
                    style={{ height: '79%' }}
                    onClick={() => setIsModalOpen(true)}
                  >
                    Add Item
                  </button>
                )}
                {activeTab === 'table2' && (
                  <button
                    className="btn btn-primary"
                    style={{ height: '79%' }}
                    onClick={() => setIsModalOpen(true)}
                  >
                    Add Property
                  </button>
                )}
              </div>
              <div className="container">
                {activeTab === 'table1' && (
                  <ItemCatalogueTable
                    data={itemsQuery.data}
                    properties={propertiesQuery.data}
                  />
                )}
                {activeTab === 'table2' && (
                  <SystemPropertiesTable properties={propertiesQuery.data} />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ItemCatalogue;
